import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import {
  getdoproundcreatecontract,
  getGemsPreSaleContract,
  getIncentivPreSaleContract,
  getLEIAPreSaleContract,
  getOlympusPreSaleContract,
  getNFAPreSaleContract,
  getAstroNovaPreSaleContract,
  getMinersClaimsContract,
  getRainPreSaleContract,
} from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";

const CuurentRound = () => {
  const [balance, setBalance] = useState(null);
  // const { account } = useWeb3React();
  const web3 = useWeb3();
  const tokenAddress = Environment.DOPRoundSingleContract;
  const contract = getdoproundcreatecontract(tokenAddress, web3);

  const gemsAddress = Environment.GemsPreSale;
  const incentivAddress = Environment.IncentivPreSale;
  const LEIAAddress = Environment.LEIAPreSale;
  const OlympusAddress = Environment.OlympusPreSale;
  const NFAAddress = Environment.NFAPreSale;
  const AstroNovaAddress = Environment.AstroNovaPreSale;
  const RainAddress = Environment.RainPreSale;

  const MinersAddress = Environment.MinersClaim;

  const gemsContractPresale = getGemsPreSaleContract(gemsAddress, web3);
  const incentivContractPresale = getIncentivPreSaleContract(
    incentivAddress,
    web3
  );

  const LEIAContractPresale = getLEIAPreSaleContract(LEIAAddress, web3);
  const OlympusContractPresale = getOlympusPreSaleContract(
    OlympusAddress,
    web3
  );

  const NFAContractPresale = getNFAPreSaleContract(NFAAddress, web3);
  const AstroNovaContractPresale = getAstroNovaPreSaleContract(
    AstroNovaAddress,
    web3
  );

  const RainContractPresale = getRainPreSaleContract(RainAddress, web3);

  const MinersContractClaims = getMinersClaimsContract(MinersAddress, web3);

  const project = localStorage.getItem("project");
  const cuurentRound = useCallback(async () => {
    if (project == "INCENTIV") {
      try {
        const buy = await incentivContractPresale.methods
          .getRoundCount()
          .call();
        return buy;
      } catch (error) {
        console.log("dlsflasdfjlasdf", error);
        throw error;
      }
    } else if (project == "NFA") {
      try {
        const buy = await NFAContractPresale.methods.getRoundCount().call();
        return buy;
      } catch (error) {
        console.log("dlsflasdfjlasdf", error);
        throw error;
      }
    } else if (project == "ASTRANOVA") {
      try {
        const buy = await AstroNovaContractPresale.methods
          .getRoundCount()
          .call();
        return buy;
      } catch (error) {
        console.log("dlsflasdfjlasdf", error);
        throw error;
      }
    } else if (project == "RAIN") {
      try {
        const buy = await RainContractPresale.methods.getRoundCount().call();
        return buy;
      } catch (error) {
        console.log("dlsflasdfjlasdf", error);
        throw error;
      }
    } else if (project == "NODEMINER") {
      try {
        const buy = await MinersContractClaims.methods.currentWeek().call();
        console.log("current week: ", buy);

        return buy;
      } catch (error) {
        console.log("dlsflasdfjlasdf", error);
        throw error;
      }
    } else if (project == "LEIA") {
      try {
        const buy = await LEIAContractPresale.methods.getRoundCount().call();
        return buy;
      } catch (error) {
        console.log("dlsflasdfjlasdf", error);
        throw error;
      }
    } else if (project == "OLYMPUS") {
      try {
        const buy = await OlympusContractPresale.methods.getRoundCount().call();
        return buy;
      } catch (error) {
        console.log("dlsflasdfjlasdf", error);
        throw error;
      }
    } else if (project == "GEMS") {
      try {
        const buy = await gemsContractPresale.methods.getRoundCount().call();
        return buy;
      } catch (error) {
        console.log("dlsflasdfjlasdf", error);
        throw error;
      }
    } else {
      try {
        const buy = await contract.methods.getRoundCount().call();
        return buy;
      } catch (error) {
        console.log("dlsflasdfjlasdf", error);
        throw error;
      }
    }
  }, [contract, web3]);
  return { cuurentRound: cuurentRound };
};
export default CuurentRound;
