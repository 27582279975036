import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import { getRainPreSaleV3Contract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";

const CurrentRoundRainV3 = () => {
  const [balance, setBalance] = useState(null);
  // const { account } = useWeb3React();
  const web3 = useWeb3();

  const RainAddressV3 = Environment.RainPreSaleV3;

  const RainContractClaimsV3 = getRainPreSaleV3Contract(RainAddressV3, web3);

  const project = localStorage.getItem("project");
  const currentRoundRainV3 = useCallback(async () => {
    if (project == "RAIN") {
      try {
        const buy = await RainContractClaimsV3.methods.getRoundCount().call();
        return buy;
      } catch (error) {
        console.log("dlsflasdfjlasdf", error);
        throw error;
      }
    }
  }, [RainContractClaimsV3, web3]);
  return { currentRoundRainV3: currentRoundRainV3 };
};
export default CurrentRoundRainV3;
