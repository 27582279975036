import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import tableup from "../../../../assets/tableup.svg";
// import tabledown from "../../../../assets/tabledown.svg";
import { Accordion, Modal } from "react-bootstrap";

import Loader from "../../../hooks/loader";
import TableRow from "./TableRow";
// import TableRowMobile from "./TableRowMobile";

const DataTable = ({
  tomiPrice,
  usdPrice,
  btcPrice,
  pepePrice,
  linkPrice,
  uniPrice,
  dopPrice,
  gemsPrice,
  claimsData,
  rend,
  setRend,
  openWalletModal,
  setOpenWalletModal,
}) => {
  const [loaderr, setLoarder] = useState(false);
  console.log("tererererer", claimsData);
  const project = localStorage.getItem("project");

  return (
    <>
      {loaderr && <Loader />}
      <section className="datatable">
        <div className="bgtable">
          <div className="table-responsive">
            <table class="table">
              <thead className="tblheadss">
                <tr>
                  <th>{project == "NODEMINER" ? "Week" : "Round"} </th>
                  <th>Amount </th>
                  <th>Status </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {/* claimsData */}
                {claimsData?.map((item, index) => {
                  console.log("data table: ", item);

                  return (
                    item?.length > 0 && (
                      <TableRow
                        item={item}
                        setRend={setRend}
                        rend={rend}
                        tomiPrice={tomiPrice}
                        usdPrice={usdPrice}
                        btcPrice={btcPrice}
                        pepePrice={pepePrice}
                        linkPrice={linkPrice}
                        uniPrice={uniPrice}
                        dopPrice={dopPrice}
                        gemsPrice={gemsPrice}
                        setLoarder={setLoarder}
                        openWalletModal={openWalletModal}
                        setOpenWalletModal={setOpenWalletModal}
                      />
                    )
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="mobileview-claim d-none">
          <div className="claim-mobile-view"></div>
        </div>
      </section>
    </>
  );
};

export default DataTable;
