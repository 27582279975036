import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getclaimscontract } from "../../utils/contractHelpers";
import { getclaimsnewcontract } from "../../utils/contractHelpers";
import { getClaimNewLatest } from "../../utils/contractHelpers";
import {
  getGemsClaimsContract,
  getIncentivClaimsContract,
  getLEIAClaimsContract,
  getOlympusClaimsContract,
  getNFAClaimsContract,
  getAstroNovaClaimsContract,
  getMinersClaimsOldContract,
  getMinersClaimsContract,
  getMinersClaimsV2Contract,
  getRainClaimsContract,
  getRainClaimsV2Contract,
  getRainClaimsV3Contract,
} from "../../utils/contractHelpers";

import { useWeb3React } from "@web3-react/core";

const AllowClaimingHook = () => {
  const web3 = useWeb3();
  const tokenAddress = environment.Claims;
  const tokenAddressNew = environment.ClaimsNew;
  const tokenAddressTomi = environment.claimNewLatest;
  const GemsContractAddress = environment.GemsClaim;
  const IncentivContractAddress = environment.IncentivClaim;
  const LEIAContractAddress = environment.LEIAClaim;
  const OlympusContractAddress = environment.OlympusClaim;
  const NFAContractAddress = environment.NFAClaim;
  const AstroNovaContractAddress = environment.AstroNovaClaim;
  const RainContractAddress = environment.RainClaim;
  const RainContractAddressV2 = environment.RainClaimV2;
  const RainContractAddressV3 = environment.RainClaimV3;

  const MinersContractAddressOld = environment.MinersClaimOld;

  const MinersContractAddress = environment.MinersClaim;
  const MinersContractAddressV2 = environment.MinersClaimV2;

  const { account } = useWeb3React();
  const contract = getclaimscontract(tokenAddress, web3);
  const contractNew = getclaimsnewcontract(tokenAddressNew, web3);
  const contractNewLatest = getClaimNewLatest(tokenAddressTomi, web3);
  const gemsContract = getGemsClaimsContract(GemsContractAddress, web3);
  const incentivContract = getIncentivClaimsContract(
    IncentivContractAddress,
    web3
  );
  const LEIAContract = getLEIAClaimsContract(LEIAContractAddress, web3);
  const OlympusContract = getOlympusClaimsContract(
    OlympusContractAddress,
    web3
  );
  const NFAContract = getNFAClaimsContract(NFAContractAddress, web3);
  const AstroNovaContract = getAstroNovaClaimsContract(
    AstroNovaContractAddress,
    web3
  );

  const RainContract = getRainClaimsContract(RainContractAddress, web3);
  const RainContractV2 = getRainClaimsV2Contract(RainContractAddressV2, web3);
  const RainContractV3 = getRainClaimsV3Contract(RainContractAddressV3, web3);

  const MinersContractOld = getMinersClaimsOldContract(
    MinersContractAddressOld,
    web3
  );

  const MinersContract = getMinersClaimsContract(MinersContractAddress, web3);
  const MinersContractV2 = getMinersClaimsV2Contract(
    MinersContractAddressV2,
    web3
  );

  const project = localStorage.getItem("project");
  const allowClaimingHook = useCallback(
    async (bool, round, decesion, nodeMinerVersion) => {
      if (project == "INCENTIV") {
        try {
          if (bool) {
            const details = await incentivContract.methods
              .isEnabled(round)
              .call();
            return details;
          } else {
            const gas = await incentivContract.methods
              .enableClaims(round, decesion)
              .estimateGas({ from: account });
            const details = await incentivContract.methods
              .enableClaims(round, decesion)
              .send({
                from: account,
                gas,
              });
            return details;
          }
        } catch (error) {
          throw error;
        }
      } else if (project == "NFA") {
        try {
          if (bool) {
            const details = await NFAContract.methods.isEnabled(round).call();
            return details;
          } else {
            const gas = await NFAContract.methods
              .enableClaims(round, decesion)
              .estimateGas({ from: account });
            const details = await NFAContract.methods
              .enableClaims(round, decesion)
              .send({
                from: account,
                gas,
              });
            return details;
          }
        } catch (error) {
          throw error;
        }
      } else if (project == "NODEMINER") {
        if (nodeMinerVersion == 1) {
          try {
            console.log(
              "round in node miner claims status hook: ",
              round,
              project
            );

            const roundEndTime = await MinersContractOld.methods
              .endTimes(round)
              .call();

            const currentEpoch = Math.floor(Date.now() / 1000);

            if (currentEpoch > roundEndTime) {
              console.log("The given time has passed.");
              return true;
            } else {
              console.log("The given time has not passed yet.");
              return false;
            }
          } catch (error) {
            console.log(error, "errorerrorerrorerrorerror");
            throw error;
          }
        } else if (nodeMinerVersion == 2) {
          try {
            const roundEndTime = await MinersContract.methods
              .endTimes(round)
              .call();

            const currentEpoch = Math.floor(Date.now() / 1000);

            if (currentEpoch > roundEndTime) {
              console.log("The given time has passed.");
              return true;
            } else {
              console.log("The given time has not passed yet.");
              return false;
            }
          } catch (error) {
            console.log(error, "errorerrorerrorerrorerror");
            throw error;
          }
        } else {
          try {
            const roundEndTime = await MinersContractV2.methods
              .endTimes(round)
              .call();

            const currentEpoch = Math.floor(Date.now() / 1000);

            if (currentEpoch > roundEndTime) {
              console.log("The given time has passed.");
              return true;
            } else {
              console.log("The given time has not passed yet.");
              return false;
            }
          } catch (error) {
            console.log(error, "errorerrorerrorerrorerror");
            throw error;
          }
        }
      } else if (project == "ASTRANOVA") {
        try {
          if (bool) {
            const details = await AstroNovaContract.methods
              .isEnabled(round)
              .call();
            return details;
          } else {
            const gas = await AstroNovaContract.methods
              .enableClaims(round, decesion)
              .estimateGas({ from: account });
            const details = await AstroNovaContract.methods
              .enableClaims(round, decesion)
              .send({
                from: account,
                gas,
              });
            return details;
          }
        } catch (error) {
          throw error;
        }
      } else if (project == "RAIN") {
        if (nodeMinerVersion == 1) {
          try {
            if (bool) {
              const details = await RainContract.methods
                .isEnabled(round)
                .call();
              return details;
            } else {
              const gas = await RainContract.methods
                .enableClaims(round, decesion)
                .estimateGas({ from: account });
              const details = await RainContract.methods
                .enableClaims(round, decesion)
                .send({
                  from: account,
                  gas,
                });
              return details;
            }
          } catch (error) {
            throw error;
          }
        } else if (nodeMinerVersion == 2) {
          try {
            if (bool) {
              const details = await RainContractV2.methods
                .isEnabled(round)
                .call();
              return details;
            } else {
              const gas = await RainContractV2.methods
                .enableClaims(round, decesion)
                .estimateGas({ from: account });
              const details = await RainContractV2.methods
                .enableClaims(round, decesion)
                .send({
                  from: account,
                  gas,
                });
              return details;
            }
          } catch (error) {
            throw error;
          }
        } else if (nodeMinerVersion == 3) {
          try {
            if (bool) {
              const details = await RainContractV3.methods
                .isEnabled(round)
                .call();
              return details;
            } else {
              const gas = await RainContractV3.methods
                .enableClaims(round, decesion)
                .estimateGas({ from: account });
              const details = await RainContractV3.methods
                .enableClaims(round, decesion)
                .send({
                  from: account,
                  gas,
                });
              return details;
            }
          } catch (error) {
            throw error;
          }
        }
      } else if (project == "LEIA") {
        try {
          if (bool) {
            const details = await LEIAContract.methods.isEnabled(round).call();
            return details;
          } else {
            const gas = await LEIAContract.methods
              .enableClaims(round, decesion)
              .estimateGas({ from: account });
            const details = await LEIAContract.methods
              .enableClaims(round, decesion)
              .send({
                from: account,
                gas,
              });
            return details;
          }
        } catch (error) {
          throw error;
        }
      } else if (project == "OLYMPUS") {
        try {
          if (bool) {
            const details = await OlympusContract.methods
              .isEnabled(round)
              .call();
            return details;
          } else {
            const gas = await OlympusContract.methods
              .enableClaims(round, decesion)
              .estimateGas({ from: account });
            const details = await OlympusContract.methods
              .enableClaims(round, decesion)
              .send({
                from: account,
                gas,
              });
            return details;
          }
        } catch (error) {
          throw error;
        }
      } else if (project == "GEMS") {
        try {
          if (bool) {
            const details = await gemsContract.methods.isEnabled(round).call();
            return details;
          } else {
            const gas = await gemsContract.methods
              .enableClaims(round, decesion)
              .estimateGas({ from: account });
            const details = await gemsContract.methods
              .enableClaims(round, decesion)
              .send({
                from: account,
                gas,
              });
            return details;
          }
        } catch (error) {
          throw error;
        }
      } else {
        if (parseInt(round) < 3) {
          try {
            if (bool) {
              const details = await contract.methods.isEnabled(round).call();
              return details;
            } else {
              const gas = await contract.methods
                .updateEnable(round, decesion)
                .estimateGas({ from: account });
              const details = await contract.methods
                .updateEnable(round, decesion)
                .send({
                  from: account,
                  gas,
                });
              return details;
            }
          } catch (error) {
            throw error;
          }
        } else if (parseInt(round) > 6) {
          try {
            if (bool) {
              const details = await contractNewLatest.methods
                .isEnabled(round)
                .call();
              return details;
            } else {
              const gas = await contractNewLatest.methods
                .enableClaims(round, decesion)
                .estimateGas({ from: account });
              const details = await contractNewLatest.methods
                .enableClaims(round, decesion)
                .send({
                  from: account,
                  gas,
                });
              return details;
            }
          } catch (error) {
            throw error;
          }
        } else {
          try {
            if (bool) {
              const details = await contractNew.methods.isEnabled(round).call();
              return details;
            } else {
              const gas = await contractNew.methods
                .updateEnable(round, decesion)
                .estimateGas({ from: account });
              const details = await contractNew.methods
                .updateEnable(round, decesion)
                .send({
                  from: account,
                  gas,
                });
              return details;
            }
          } catch (error) {
            throw error;
          }
        }
      }
    },
    [contract]
  );
  return { allowClaimingHook: allowClaimingHook };
};
export default AllowClaimingHook;
