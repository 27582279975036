import React from "react";
import "./kyc.scss";
import Header from "../landing/navbar/Header";
import Footer from "../landing/footer/Footer";
const Kycrequired = () => {
  return (
    <>
    <Header />
      <section className="kycmain">
        <div className="kycinner">
          <div className="innertop">
            <h6 className="topheading">KYC Required</h6>
          </div>
          <div className="innerbody">
            <p className="kycpara">
              Before you can proceed to your Gems Leaders Dashboard, you must
              complete the KYC (Know Your Customer) verification process. Please
              follow the external link to complete this step.
            </p>
            <p className="kycpara">
              If you have already submitted your KYC details, thank you for
              doing so. Our team is currently reviewing your information.{" "}
            </p>
            <p className="kycpara">
              Please check back later to see if your verification has been
              approved and to proceed to your leader dashboard
            </p>
            <button className="kycbutton">
              Start KYC
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
              >
                <path
                  d="M9.83333 2.5V3.83333H12.2267L5.67333 10.3867L6.61333 11.3267L13.1667 4.77333V7.16667H14.5V2.5M13.1667 13.1667H3.83333V3.83333H8.5V2.5H3.83333C3.47971 2.5 3.14057 2.64048 2.89052 2.89052C2.64048 3.14057 2.5 3.47971 2.5 3.83333V13.1667C2.5 13.5203 2.64048 13.8594 2.89052 14.1095C3.14057 14.3595 3.47971 14.5 3.83333 14.5H13.1667C13.5203 14.5 13.8594 14.3595 14.1095 14.1095C14.3595 13.8594 14.5 13.5203 14.5 13.1667V8.5H13.1667V13.1667Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Kycrequired;
