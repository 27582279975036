import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import { getRainPreSaleV2Contract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";

const CurrentRoundRainV2 = () => {
  const [balance, setBalance] = useState(null);
  // const { account } = useWeb3React();
  const web3 = useWeb3();

  const RainAddressV2 = Environment.RainPreSaleV2;

  const RainContractClaimsV2 = getRainPreSaleV2Contract(RainAddressV2, web3);

  const project = localStorage.getItem("project");
  const currentRoundRainV2 = useCallback(async () => {
    if (project == "RAIN") {
      try {
        const buy = await RainContractClaimsV2.methods.getRoundCount().call();
        return buy;
      } catch (error) {
        console.log("dlsflasdfjlasdf", error);
        throw error;
      }
    }
  }, [RainContractClaimsV2, web3]);
  return { currentRoundRainV2: currentRoundRainV2 };
};
export default CurrentRoundRainV2;
