import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import {
  getclaimscontract,
  getclaimsnewcontract,
  getGemsClaimsContract,
  getIncentivClaimsContract,
  getLEIAClaimsContract,
  getOlympusClaimsContract,
  getNFAClaimsContract,
  getAstroNovaClaimsContract,
  getMinersClaimsOldContract,
  getMinersClaimsContract,
  getMinersClaimsV2Contract,
  getRainClaimsContract,
  getRainClaimsV2Contract,
  getRainClaimsV3Contract,
} from "../../utils/contractHelpers";
import { getClaimNewLatest } from "../../utils/contractHelpers";
const AllowClaimHook = () => {
  const web3 = useWeb3();
  const tokenAddress = environment.Claims;
  const contract = getclaimscontract(tokenAddress, web3);
  const tokenAddressNew = environment.ClaimsNew;
  const contractNew = getclaimsnewcontract(tokenAddressNew, web3);
  const tokenAddressTomi = environment.claimNewLatest;
  const contractNewLatest = getClaimNewLatest(tokenAddressTomi, web3);
  const gemsAddress = environment.GemsClaim;
  const incentivAddress = environment.IncentivClaim;
  const LEIAAddress = environment.LEIAClaim;
  const OlympusAddress = environment.OlympusClaim;
  const NFAAddress = environment.NFAClaim;
  const AstroNovaAddress = environment.AstroNovaClaim;
  const RainAddress = environment.RainClaim;
  const RainAddressV2 = environment.RainClaimV2;
  const RainAddressV3 = environment.RainClaimV3;

  const MinersAddressOld = environment.MinersClaimOld;

  const MinersAddress = environment.MinersClaim;
  const MinersAddressV2 = environment.MinersClaimV2;

  const gemsContract = getGemsClaimsContract(gemsAddress, web3);
  const incentivContract = getIncentivClaimsContract(incentivAddress, web3);
  const LEIAContract = getLEIAClaimsContract(LEIAAddress, web3);
  const OlympusContract = getOlympusClaimsContract(OlympusAddress, web3);
  const NFAContract = getNFAClaimsContract(NFAAddress, web3);
  const AstroNovaContract = getAstroNovaClaimsContract(AstroNovaAddress, web3);
  const RainContract = getRainClaimsContract(RainAddress, web3);

  const RainContractV2 = getRainClaimsV2Contract(RainAddressV2, web3);
  const RainContractV3 = getRainClaimsV3Contract(RainAddressV3, web3);

  const MinersContractOld = getMinersClaimsOldContract(MinersAddressOld, web3);

  const MinersContract = getMinersClaimsContract(MinersAddress, web3);
  const MinersContractV2 = getMinersClaimsV2Contract(MinersAddressV2, web3);

  const project = localStorage.getItem("project");
  const allowClaimHook = useCallback(
    async (account, round, tokensAddressArray, nodeMinerVersion) => {
      if (project == "INCENTIV") {
        try {
          const gas = await incentivContract.methods
            .claim(round, tokensAddressArray)
            .estimateGas({ from: account });

          const details = await incentivContract.methods
            .claim(round, tokensAddressArray)
            .send({
              from: account,
              gas,
            });
          return details;
        } catch (error) {
          throw error;
        }
      } else if (project == "NFA") {
        try {
          const gas = await NFAContract.methods
            .claim(round, tokensAddressArray)
            .estimateGas({ from: account });
          const details = await NFAContract.methods
            .claim(round, tokensAddressArray)
            .send({
              from: account,
              gas,
            });
          return details;
        } catch (error) {
          throw error;
        }
      } else if (project == "NODEMINER") {
        if (nodeMinerVersion == 1) {
          try {
            // THIS PART IS FOR TESTING ON TESTNET CONTRACTS

            // SHOULD BE COMMENTED IN MAINNET           !!!!!!!!     IMPORTANT     !!!!!!!!!!

            // const gas = await MinersContractOld.methods
            //   .claimAll([round], [tokensAddressArray])
            //   .estimateGas({ from: account });
            // const details = await MinersContractOld.methods
            //   .claimAll([round], [tokensAddressArray])
            //   .send({
            //     from: account,
            //     gas,
            //   });

            // THIS PART IS FOR MAINNET CONTRACTS

            // SHOULD BE UN-COMMENTED IN MAINNET           !!!!!!!!     IMPORTANT     !!!!!!!!!!

            const gas = await MinersContractOld.methods
              .claim(round, tokensAddressArray)
              .estimateGas({ from: account });
            const details = await MinersContractOld.methods
              .claim(round, tokensAddressArray)
              .send({
                from: account,
                gas,
              });
            return details;
          } catch (error) {
            throw error;
          }
        } else if (nodeMinerVersion == 2) {
          try {
            const gas = await MinersContract.methods
              .claimAll(round, tokensAddressArray)
              .estimateGas({ from: account });
            const details = await MinersContract.methods
              .claimAll(round, tokensAddressArray)
              .send({
                from: account,
                gas,
              });
            return details;
          } catch (error) {
            throw error;
          }
        } else {
          try {
            const gas = await MinersContractV2.methods
              .claimAll(round, tokensAddressArray)
              .estimateGas({ from: account });
            const details = await MinersContractV2.methods
              .claimAll(round, tokensAddressArray)
              .send({
                from: account,
                gas,
              });
            return details;
          } catch (error) {
            throw error;
          }
        }
      } else if (project == "ASTRANOVA") {
        try {
          const gas = await AstroNovaContract.methods
            .claim(round, tokensAddressArray)
            .estimateGas({ from: account });
          const details = await AstroNovaContract.methods
            .claim(round, tokensAddressArray)
            .send({
              from: account,
              gas,
            });
          return details;
        } catch (error) {
          throw error;
        }
      } else if (project == "RAIN") {
        if (nodeMinerVersion == 1) {
          try {
            const gas = await RainContract.methods
              .claim(round, tokensAddressArray)
              .estimateGas({ from: account });
            const details = await RainContract.methods
              .claim(round, tokensAddressArray)
              .send({
                from: account,
                gas,
              });
            return details;
          } catch (error) {
            throw error;
          }
        } else if (nodeMinerVersion == 2) {
          try {
            const gas = await RainContractV2.methods
              .claim(round, tokensAddressArray)
              .estimateGas({ from: account });
            const details = await RainContractV2.methods
              .claim(round, tokensAddressArray)
              .send({
                from: account,
                gas,
              });
            return details;
          } catch (error) {
            throw error;
          }
        } else if (nodeMinerVersion == 3) {
          try {
            const gas = await RainContractV3.methods
              .claim(round, tokensAddressArray)
              .estimateGas({ from: account });
            const details = await RainContractV3.methods
              .claim(round, tokensAddressArray)
              .send({
                from: account,
                gas,
              });
            return details;
          } catch (error) {
            throw error;
          }
        }
      } else if (project == "LEIA") {
        try {
          const gas = await LEIAContract.methods
            .claim(round, tokensAddressArray)
            .estimateGas({ from: account });
          const details = await LEIAContract.methods
            .claim(round, tokensAddressArray)
            .send({
              from: account,
              gas,
            });
          return details;
        } catch (error) {
          throw error;
        }
      } else if (project == "OLYMPUS") {
        try {
          const gas = await OlympusContract.methods
            .claim(round, tokensAddressArray)
            .estimateGas({ from: account });
          const details = await OlympusContract.methods
            .claim(round, tokensAddressArray)
            .send({
              from: account,
              gas,
            });
          return details;
        } catch (error) {
          throw error;
        }
      } else if (project == "GEMS") {
        try {
          const gas = await gemsContract.methods
            .claim(round, tokensAddressArray)
            .estimateGas({ from: account });
          const details = await gemsContract.methods
            .claim(round, tokensAddressArray)
            .send({
              from: account,
              gas,
            });
          return details;
        } catch (error) {
          throw error;
        }
      } else {
        if (parseInt(round) < 3) {
          try {
            const gas = await contract.methods
              .claim(round)
              .estimateGas({ from: account });
            const details = await contract.methods.claim(round).send({
              from: account,
              gas,
            });
            return details;
          } catch (error) {
            throw error;
          }
        } else if (parseInt(round) > 6) {
          try {
            const gas = await contractNewLatest.methods
              .claim(round, tokensAddressArray)
              .estimateGas({ from: account });
            const details = await contractNewLatest.methods
              .claim(round, tokensAddressArray)
              .send({
                from: account,
                gas,
              });
            return details;
          } catch (error) {
            throw error;
          }
        } else {
          try {
            const gas = await contractNew.methods
              .claim(round)
              .estimateGas({ from: account });
            const details = await contractNew.methods.claim(round).send({
              from: account,
              gas,
            });
            return details;
          } catch (error) {
            throw error;
          }
        }
      }
    },
    [contract]
  );
  return { allowClaimHook: allowClaimHook };
};
export default AllowClaimHook;
