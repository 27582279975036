import React from 'react'
import './kyc.scss'
import Header from '../landing/navbar/Header'
import Footer from '../landing/footer/Footer'
const Kycfailed = () => {
  return (
    <>
        <Header />
      <section className="kycmain">
        <div className="kycinner">
          <div className="innertop">
            <h6 className="topheading failed">KYC Failed</h6>
          </div>
          <div className="innerbody">
            <p className="kycpara">
            Unfortunately, our compliance system has flagged your account as high-risk. As a result, we cannot let you continue using Gems Leaders Dashbaord according to our Terms and Conditions.
            </p>
            <p className="kycpara">
            We apologize for any inconvenience this may cause.
            </p>
            <p className="kycpara mb-0">
            If you feel this was an error, please reach out to our support team on <a href="" className='supportlink'>support@gems.vip</a>
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default Kycfailed
