import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import { getGemsTokenContract } from "../../utils/contractHelpers";
// import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";

const AllowanceGems = () => {
  // const [balance, setBalance] = useState(null)
  //   const { account } = useWeb3React();
  const web3 = useWeb3();
  const tokenAddress = Environment.GemsSubscription;
  const tokenAddress1 = Environment.gems;
  const contract = getGemsTokenContract(tokenAddress1, web3);

  const allowanceGems = useCallback(
    async (account) => {
      // console.log(tokenAddress,account,'noor');
      try {
        const buy = await contract.methods
          .allowance(account, tokenAddress)
          .call();

        const allow = parseInt(buy.toString()) / 10 ** 18;

        return allow;
      } catch (error) {
        throw error;
      }
    },
    [contract, web3]
  );
  return { allowanceGems: allowanceGems };
};
export default AllowanceGems;
