import React, { useState, useEffect } from "react";
import "./myclaims.scss";
import Dropdown from "react-bootstrap/Dropdown";
import Header from "../landing/navbar/Header";
import Footerline from "../landing/footer/Footerline";
import CuurentRound from "../../hooks/dataFetchers/round";
import CurrentRoundRainV2 from "../../hooks/dataFetchers/roundRainV2";
import CurrentRoundRainV3 from "../../hooks/dataFetchers/roundRainV3";

import CurrentRoundNodeMinerOld from "../../hooks/dataFetchers/roundNodeMinerOld";
import CurrentRoundNodeMinerV2 from "../../hooks/dataFetchers/roundNodeMinerV2";

import DataTable from "./DataTable/DataTable";
import DataTable2 from "./DataTable2/DataTable";
import axios from "axios";
import { Api_Url } from "../../utils/ApiUrl";
import Loader from "../../hooks/loader";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Listed from "../../hooks/dataFetchers/isActivatedLEIA";
import AllClaimableNodeMiner from "../../hooks/dataFetchers/allPendingClaimsNodeMiner";
import Environment from "../../utils/Environment";

const Myclaims = ({
  usdPrice,
  tomiPrice,
  btcPrice,
  pepePrice,
  linkPrice,
  uniPrice,
  dopPrice,
  gemsPrice,
}) => {
  const { CheckAllClaimableNodeMiner } = AllClaimableNodeMiner();

  const { IsActivated } = Listed();
  const [show1, setShow1] = useState(false);
  const [openWalletModal, setOpenWalletModal] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const project = localStorage.getItem("project");

  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(10);

  const [count, setCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const [pages, setPages] = useState([]);
  const [page, setPage] = useState(0);
  const [claimsDataV1, setClaimsDataV1] = useState([]);
  const [claimsDataV2, setClaimsDataV2] = useState([]);
  const [claimsDataV3, setClaimsDataV3] = useState([]);

  const [claimsDataRainV2, setClaimsDataRainV2] = useState([]);

  const [claimsDataTotal, setClaimsDataTotal] = useState([]);
  const [pendingClaimsData, setPendingClaimsData] = useState([]);

  const [claimsDataInsured, setClaimsDataInsured] = useState([]);
  const [claimsDataUnInsured, setClaimsDataUnInsured] = useState([]);

  const [tokenListedBool, setTokenListedBool] = useState(false);

  const [rend, setRend] = useState(false);
  const tok = localStorage.getItem("accessToken");
  const { cuurentRound } = CuurentRound();
  const { currentRoundRainV2 } = CurrentRoundRainV2();
  const { currentRoundRainV3 } = CurrentRoundRainV3();

  const { currentRoundNodeMinerOld } = CurrentRoundNodeMinerOld();
  const { currentRoundNodeMinerV2 } = CurrentRoundNodeMinerV2();

  const user = localStorage.getItem("user");
  const { walletAddress } = JSON.parse(user);

  const history = useHistory();

  const handlePageChange = (e) => {
    const selectedPage = e.selected;

    setPage(selectedPage);
  };

  const getTokenListedBool = async () => {
    let b = await IsActivated();
    if (b) {
      setTokenListedBool(b);
    }
  };

  const checkPendingClaims = async (round) => {
    //week:${round},
    var dataMiners = JSON.stringify({
      query: `query MyQuery {
  leaderToTokens(where:{leader:"${walletAddress}",claimed:false}){
    leader
    token
    week
    claimed
  }



  }`,
      variables: {},
    });

    var config = {
      method: "post",
      url: Environment?.MinersGraph,
      headers: {
        "Content-Type": "application/json",
      },
      data: dataMiners,
    };
    axios(config).then(async (response) => {
      console.log("pending claimss data from graph: ", response?.data?.data);
      const resData = response?.data?.data?.leaderToTokens;

      let weekTokenMap = {};

      // Iterate over response data
      resData.forEach(({ week, token }) => {
        if (!weekTokenMap[week]) {
          weekTokenMap[week] = [];
        }
        weekTokenMap[week].push(token);
      });

      // Step 2: Extract weeks in sorted order
      let weeks = Object.keys(weekTokenMap)
        .map(Number)
        .sort((a, b) => a - b);

      // Step 3: Get tokens array in the same order as weeks
      let tokens = weeks.map((week) => weekTokenMap[week]);

      console.log("Weeks:", weeks);
      console.log("Tokens:", tokens);

      // setPendingClaimsData(response?.data?.data);
    });
  };

  const getClaimsDataV1 = () => {
    var config = {
      method: "get",
      url: `${Api_Url}/users/my-claims?privateSale=${project}&version=1`,

      headers: {
        Authorization: "Bearer " + tok,
      },
    };

    axios(config)
      .then(async function (res) {
        let dumArrayInsured = [];
        let dumArrayUnInsured = [];
        let dumArrayTotal = [];
        let roundOld;
        if (project == "NODEMINER") {
          roundOld = await currentRoundNodeMinerOld();
        } else if (project == "RAIN") {
          roundOld = await cuurentRound();
        }

        for (let i = 1; i <= roundOld; i++) {
          let resTot = res?.data?.data?.totalClaims
            ?.filter((item) => item?._id?.round === i)
            .map((item) => ({
              ...item,
              _id: { ...item._id, version: 1 }, // Adding version: 1 inside _id
            }));

          console.log(
            "claims data v1: ",
            roundOld,
            resTot,
            res?.data?.data?.totalClaims
          );
          dumArrayTotal.push(resTot);
        }

        setClaimsDataV1(dumArrayTotal);

        setCount(res.data.data.count);
        let arr = Array.from(Array(parseInt(res.data.data.pages)).keys());
        setPageCount(res.data.data.pages);
        setPages(arr);
        // // console.log('dumArrray', dumArray)
        // setLoader(false);
      })
      .catch(function (error) {
        if (error?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          history.push("/login");
        }
        // setLoader(false);
      });
  };

  const getClaimsDataV3 = () => {
    var config = {
      method: "get",
      url: `${Api_Url}/users/my-claims?privateSale=${project}&version=3`,

      headers: {
        Authorization: "Bearer " + tok,
      },
    };

    axios(config)
      .then(async function (res) {
        let dumArrayInsured = [];
        let dumArrayUnInsured = [];
        let dumArrayTotal = [];

        let roundOld;
        if (project == "NODEMINER") {
          roundOld = await currentRoundNodeMinerV2();
        } else if (project == "RAIN") {
          roundOld = await currentRoundRainV3();
        }

        for (let i = 1; i <= roundOld; i++) {
          let resTot = res?.data?.data?.totalClaims
            ?.filter((item) => item?._id?.round === i)
            .map((item) => ({
              ...item,
              _id: { ...item._id, version: 3 }, // Adding version: 1 inside _id
            }));

          console.log(
            "claims data v1: ",
            roundOld,
            resTot,
            res?.data?.data?.totalClaims
          );
          dumArrayTotal.push(resTot);
        }

        setClaimsDataV3(dumArrayTotal);

        setCount(res.data.data.count);
        let arr = Array.from(Array(parseInt(res.data.data.pages)).keys());
        setPageCount(res.data.data.pages);
        setPages(arr);
        // // console.log('dumArrray', dumArray)
        // setLoader(false);
      })
      .catch(function (error) {
        if (error?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          history.push("/login");
        }
        // setLoader(false);
      });
  };

  const getClaimsDataV2 = () => {
    var config = {
      method: "get",
      url: `${Api_Url}/users/my-claims?privateSale=${project}&version=2`,

      headers: {
        Authorization: "Bearer " + tok,
      },
    };

    axios(config)
      .then(async function (res) {
        let dumArrayInsured = [];
        let dumArrayUnInsured = [];
        let dumArrayTotal = [];

        let roundOld;
        if (project == "NODEMINER") {
          roundOld = await cuurentRound();
        } else if (project == "RAIN") {
          roundOld = await currentRoundRainV2();
        }

        for (let i = 1; i <= roundOld; i++) {
          let resTot = res?.data?.data?.totalClaims
            ?.filter((item) => item?._id?.round === i)
            .map((item) => ({
              ...item,
              _id: { ...item._id, version: 2 }, // Adding version: 1 inside _id
            }));

          console.log(
            "claims data v1: ",
            roundOld,
            resTot,
            res?.data?.data?.totalClaims
          );
          dumArrayTotal.push(resTot);
        }

        setClaimsDataV2(dumArrayTotal);

        setCount(res.data.data.count);
        let arr = Array.from(Array(parseInt(res.data.data.pages)).keys());
        setPageCount(res.data.data.pages);
        setPages(arr);
        // // console.log('dumArrray', dumArray)
        // setLoader(false);
      })
      .catch(function (error) {
        if (error?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          history.push("/login");
        }
        // setLoader(false);
      });
  };

  const getClaimsData = () => {
    var config = {
      method: "get",
      url: `${Api_Url}/users/my-claims?privateSale=${project}`,

      headers: {
        Authorization: "Bearer " + tok,
      },
    };

    axios(config)
      .then(async function (res) {
        let dumArrayInsured = [];
        let dumArrayUnInsured = [];
        let dumArrayTotal = [];

        // let roundOld = await currentRoundNodeMinerOld();
        let roundNew = await cuurentRound();
        // let totalRound = parseInt(roundOld) + parseInt(roundNew);

        console.log("total rounds: ", roundNew);

        for (let i = 1; i <= roundNew; i++) {
          let resTot = res?.data?.data?.totalClaims?.filter(
            (item) => item?._id?.round === i
          );
          console.log(
            "claims data v2: ",
            roundNew,
            resTot,
            res?.data?.data?.totalClaims
          );

          dumArrayTotal.push(resTot);

          // let res1 = res?.data?.data?.insuredClaims?.filter(
          //   (item) => item?._id?.round === i
          // );
          // dumArrayInsured.push(res1);

          // let res2 = res?.data?.data?.unInsuredClaims?.filter(
          //   (item) => item?._id?.round === i
          // );
          // dumArrayUnInsured.push(res2);
        }

        setClaimsDataTotal(dumArrayTotal);
        setClaimsDataInsured(dumArrayInsured);
        setClaimsDataUnInsured(dumArrayUnInsured);

        setCount(res.data.data.count);
        let arr = Array.from(Array(parseInt(res.data.data.pages)).keys());
        setPageCount(res.data.data.pages);
        setPages(arr);
        // // console.log('dumArrray', dumArray)
        // setLoader(false);
      })
      .catch(function (error) {
        if (error?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          history.push("/login");
        }
        // setLoader(false);
      });
  };

  useEffect(() => {
    if (user) {
      console.log("porject: ", project);

      if (project == "NODEMINER" || project == "RAIN") {
        getClaimsDataV1();
        getClaimsDataV2();
        getClaimsDataV3();
      } else {
        console.log("running else ");

        getClaimsData();
      }
      // checkPendingClaims();
      getTokenListedBool();
    }
  }, []);

  const renderTooltip = (props) => (
    <Tooltip id="custom-tooltip-claim" {...props}>
      <h6>claims for UNinsured purchases</h6>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt
        mollit anim id est laborum.
      </p>
    </Tooltip>
  );

  return (
    <>
      <Header
        openWalletModal={openWalletModal}
        setOpenWalletModal={setOpenWalletModal}
      />
      <>
        <section className="mainclaims">
          <div className="theme-container">
            <div className="parent">
              <div
                className="left w-100"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h2>My Claims</h2>
                {/* {project == "NODEMINER" ? (
                  <button className="btn-claimallpending">
                    Claim all pending
                  </button>
                ) : null} */}
              </div>
              {/* <div className='right'>
                                <div className='innerright'>
                                    <input type='text' placeholder='Search...' />
                                    <img src='\assets\dashboardassets\search-normal.svg' alt='img' className='img-fluid' />
                                </div>
                                <div className='dropbtn'>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            No. Of Purchases
                                            <img src='\assets\dashboardassets\arrowdropdown.svg' alt='img' className='img-fluid' />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div> */}
            </div>
            {/* <div className='card'>
                            <div className='innercontent'>
                                <img src='\assets\dashboardassets\Framehand.svg' alt='img' className='img-fluid' />
                                <h3>You have no claims yet</h3>
                                <p>Sed omnis repellendus id vitae rerum et amet veritatis</p>
                            </div>
                        </div> */}

            {/* <nav className="claim-navtab">
              <div class="nav nav-tabs" id="nav-tab" role="tablist">
                <button
                  class="nav-link active"
                  id="nav-home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-home"
                  type="button"
                  role="tab"
                  aria-controls="nav-home"
                  aria-selected="true"
                >
                  claims for UNinsured purchases
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                    className="custom-tooltip-claim"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="24"
                      viewBox="0 0 25 24"
                      fill="none"
                    >
                      <path
                        d="M11.25 17H13.25V11H11.25V17ZM12.25 9C12.5333 9 12.771 8.904 12.963 8.712C13.155 8.52 13.2507 8.28267 13.25 8C13.25 7.71667 13.154 7.47933 12.962 7.288C12.77 7.09667 12.5327 7.00067 12.25 7C11.9667 7 11.7293 7.096 11.538 7.288C11.3467 7.48 11.2507 7.71733 11.25 8C11.25 8.28333 11.346 8.521 11.538 8.713C11.73 8.905 11.9673 9.00067 12.25 9ZM12.25 22C10.8667 22 9.56667 21.7373 8.35 21.212C7.13333 20.6867 6.075 19.9743 5.175 19.075C4.275 18.175 3.56267 17.1167 3.038 15.9C2.51333 14.6833 2.25067 13.3833 2.25 12C2.25 10.6167 2.51267 9.31667 3.038 8.1C3.56333 6.88333 4.27567 5.825 5.175 4.925C6.075 4.025 7.13333 3.31267 8.35 2.788C9.56667 2.26333 10.8667 2.00067 12.25 2C13.6333 2 14.9333 2.26267 16.15 2.788C17.3667 3.31333 18.425 4.02567 19.325 4.925C20.225 5.825 20.9377 6.88333 21.463 8.1C21.9883 9.31667 22.2507 10.6167 22.25 12C22.25 13.3833 21.9873 14.6833 21.462 15.9C20.9367 17.1167 20.2243 18.175 19.325 19.075C18.425 19.975 17.3667 20.6877 16.15 21.213C14.9333 21.7383 13.6333 22.0007 12.25 22ZM12.25 20C14.4833 20 16.375 19.225 17.925 17.675C19.475 16.125 20.25 14.2333 20.25 12C20.25 9.76667 19.475 7.875 17.925 6.325C16.375 4.775 14.4833 4 12.25 4C10.0167 4 8.125 4.775 6.575 6.325C5.025 7.875 4.25 9.76667 4.25 12C4.25 14.2333 5.025 16.125 6.575 17.675C8.125 19.225 10.0167 20 12.25 20Z"
                        fill="black"
                      />
                    </svg>
                  </OverlayTrigger>{" "}
                </button>
                <button
                  class="nav-link"
                  id="nav-profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-profile"
                  type="button"
                  role="tab"
                  aria-controls="nav-profile"
                  aria-selected="false"
                >
                  claims for insured purchases{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                  >
                    <path
                      d="M11.25 17H13.25V11H11.25V17ZM12.25 9C12.5333 9 12.771 8.904 12.963 8.712C13.155 8.52 13.2507 8.28267 13.25 8C13.25 7.71667 13.154 7.47933 12.962 7.288C12.77 7.09667 12.5327 7.00067 12.25 7C11.9667 7 11.7293 7.096 11.538 7.288C11.3467 7.48 11.2507 7.71733 11.25 8C11.25 8.28333 11.346 8.521 11.538 8.713C11.73 8.905 11.9673 9.00067 12.25 9ZM12.25 22C10.8667 22 9.56667 21.7373 8.35 21.212C7.13333 20.6867 6.075 19.9743 5.175 19.075C4.275 18.175 3.56267 17.1167 3.038 15.9C2.51333 14.6833 2.25067 13.3833 2.25 12C2.25 10.6167 2.51267 9.31667 3.038 8.1C3.56333 6.88333 4.27567 5.825 5.175 4.925C6.075 4.025 7.13333 3.31267 8.35 2.788C9.56667 2.26333 10.8667 2.00067 12.25 2C13.6333 2 14.9333 2.26267 16.15 2.788C17.3667 3.31333 18.425 4.02567 19.325 4.925C20.225 5.825 20.9377 6.88333 21.463 8.1C21.9883 9.31667 22.2507 10.6167 22.25 12C22.25 13.3833 21.9873 14.6833 21.462 15.9C20.9367 17.1167 20.2243 18.175 19.325 19.075C18.425 19.975 17.3667 20.6877 16.15 21.213C14.9333 21.7383 13.6333 22.0007 12.25 22ZM12.25 20C14.4833 20 16.375 19.225 17.925 17.675C19.475 16.125 20.25 14.2333 20.25 12C20.25 9.76667 19.475 7.875 17.925 6.325C16.375 4.775 14.4833 4 12.25 4C10.0167 4 8.125 4.775 6.575 6.325C5.025 7.875 4.25 9.76667 4.25 12C4.25 14.2333 5.025 16.125 6.575 17.675C8.125 19.225 10.0167 20 12.25 20Z"
                      fill="black"
                    />
                  </svg>
                </button>
              </div>
            </nav> */}
            <div class="tab-content" id="nav-tabContent">
              <div
                class="tab-pane fade show active"
                id="nav-home"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
                tabindex="0"
              >
                <DataTable
                  tomiPrice={tomiPrice}
                  usdPrice={usdPrice}
                  btcPrice={btcPrice}
                  pepePrice={pepePrice}
                  linkPrice={linkPrice}
                  uniPrice={uniPrice}
                  dopPrice={dopPrice}
                  gemsPrice={gemsPrice}
                  // claimsData={claimsDataUnInsured}
                  claimsData={[
                    ...claimsDataV1,
                    ...claimsDataTotal,
                    ...claimsDataV2,
                    ...claimsDataV3,
                  ]} // will be changed to uninsured when going live with insurance flow
                  rend={rend}
                  setRend={setRend}
                  openWalletModal={openWalletModal}
                  setOpenWalletModal={setOpenWalletModal}
                />

                <div className="main-card">
                  <div className="table-responsive">
                    <table className="table">
                      {/* <thead className="tblheadss">
                    <tr>
                      <th>
                        Round{" "}
                        <img
                          src="\Group120.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </th>
                      <th>
                        Amount{" "}
                        <img
                          src="\Group120.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </th>
                      <th>
                        Status{" "}
                        <img
                          src="\Group120.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </th>
                    </tr>
                  </thead> */}
                      {/* {claimsData.map((item,index) => {
                    return(
                    <tbody>
                    
                      <tr key={index}>
                        <td>
                          <h6 className="round">  {item[0]?._id?.round}</h6>
                        </td>                 
                        <td>
                        <h6>{item?.[index]?.amount}</h6>
                        <p>
                         
                           {item[0]?._id?.tokenSelected}</p>
                      </td>
                    
                      <td>
                        <p className="green">Allowed</p>
                      </td>
                  <td>
                                                <button className='claim'>Claim</button>
                                            </td>
                      </tr>
                    </tbody>)
                  })} */}
                    </table>
                  </div>
                  <div className="footer-content">
                    <div className="left-f">
                      <h6>
                        {" "}
                        {count > 0 && count <= limit ? (
                          <>
                            <p>
                              SHOWING 1-{count} OF {count}
                            </p>
                          </>
                        ) : count > limit ? (
                          <>
                            <p>
                              SHOWING {page > 1 ? (page - 1) * limit + 1 : page}{" "}
                              -{limit * page > count ? count : limit * page} OF{" "}
                              {count}
                            </p>
                          </>
                        ) : (
                          <>
                            <p>SHOWING 0 OF 0</p>
                          </>
                        )}
                      </h6>
                    </div>
                    <div className="right-f">
                      <nav aria-label="Page navigation example">
                        <ul class="pagination">
                          <nav aria-label="Page navigation example">
                            <ul class="text-center">
                              <ReactPaginate
                                previousLabel={
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15"
                                    height="15"
                                    viewBox="0 0 15 15"
                                    fill="none"
                                  >
                                    <path
                                      d="M10 13L5 8L10 3"
                                      stroke="#fff"
                                      stroke-width="1.5"
                                      stroke-miterlimit="10"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                }
                                nextLabel={
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15"
                                    height="15"
                                    viewBox="0 0 15 15"
                                    fill="none"
                                  >
                                    <path
                                      d="M5 13L10 8L5 3"
                                      stroke="#fff"
                                      stroke-width="1.5"
                                      stroke-miterlimit="10"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                }
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageChange}
                                containerClassName="pagination"
                                activeClassName="active"
                                forcePage={page}
                              ></ReactPaginate>
                            </ul>
                          </nav>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="nav-profile"
                role="tabpanel"
                aria-labelledby="nav-profile-tab"
                tabindex="0"
              >
                <DataTable2
                  tomiPrice={tomiPrice}
                  usdPrice={usdPrice}
                  btcPrice={btcPrice}
                  pepePrice={pepePrice}
                  linkPrice={linkPrice}
                  uniPrice={uniPrice}
                  dopPrice={dopPrice}
                  gemsPrice={gemsPrice}
                  claimsData={claimsDataInsured}
                  rend={rend}
                  setRend={setRend}
                  openWalletModal={openWalletModal}
                  setOpenWalletModal={setOpenWalletModal}
                  tokenListedBool={tokenListedBool}
                />

                <div className="main-card">
                  <div className="table-responsive">
                    <table className="table">
                      {/* <thead className="tblheadss">
                    <tr>
                      <th>
                        Round{" "}
                        <img
                          src="\Group120.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </th>
                      <th>
                        Amount{" "}
                        <img
                          src="\Group120.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </th>
                      <th>
                        Status{" "}
                        <img
                          src="\Group120.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </th>
                    </tr>
                  </thead> */}
                      {/* {claimsData.map((item,index) => {
                    return(
                    <tbody>
                    
                      <tr key={index}>
                        <td>
                          <h6 className="round">  {item[0]?._id?.round}</h6>
                        </td>                 
                        <td>
                        <h6>{item?.[index]?.amount}</h6>
                        <p>
                         
                           {item[0]?._id?.tokenSelected}</p>
                      </td>
                    
                      <td>
                        <p className="green">Allowed</p>
                      </td>
                  <td>
                                                <button className='claim'>Claim</button>
                                            </td>
                      </tr>
                    </tbody>)
                  })} */}
                    </table>
                  </div>
                  <div className="footer-content">
                    <div className="left-f">
                      <h6>
                        {" "}
                        {count > 0 && count <= limit ? (
                          <>
                            <p>
                              SHOWING 1-{count} OF {count}
                            </p>
                          </>
                        ) : count > limit ? (
                          <>
                            <p>
                              SHOWING {page > 1 ? (page - 1) * limit + 1 : page}{" "}
                              -{limit * page > count ? count : limit * page} OF{" "}
                              {count}
                            </p>
                          </>
                        ) : (
                          <>
                            <p>SHOWING 0 OF 0</p>
                          </>
                        )}
                      </h6>
                    </div>
                    <div className="right-f">
                      <nav aria-label="Page navigation example">
                        <ul class="pagination">
                          <nav aria-label="Page navigation example">
                            <ul class="text-center">
                              <ReactPaginate
                                previousLabel={
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15"
                                    height="15"
                                    viewBox="0 0 15 15"
                                    fill="none"
                                  >
                                    <path
                                      d="M10 13L5 8L10 3"
                                      stroke="#fff"
                                      stroke-width="1.5"
                                      stroke-miterlimit="10"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                }
                                nextLabel={
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15"
                                    height="15"
                                    viewBox="0 0 15 15"
                                    fill="none"
                                  >
                                    <path
                                      d="M5 13L10 8L5 3"
                                      stroke="#fff"
                                      stroke-width="1.5"
                                      stroke-miterlimit="10"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                }
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageChange}
                                containerClassName="pagination"
                                activeClassName="active"
                                forcePage={page}
                              ></ReactPaginate>
                            </ul>
                          </nav>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footerline />
      </>
    </>
  );
};

export default Myclaims;
