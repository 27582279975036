import React, { useState, useEffect } from "react";
import "./leaders.scss";
import Header from "../landing/navbar/Header";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Calendar } from "react-multi-date-picker";
import Datatable from "./DataTable/Datatable";
import Footerline from "../landing/footer/Footerline";
import axios from "axios";
import { Api_Url } from "../../utils/ApiUrl";
import { useHistory } from "react-router-dom";
import CuurentRound from "../../hooks/dataFetchers/round";
import { Dropdown } from "react-bootstrap";

const Leaders = ({ tomiPrice, usdPrice, btcPrice }) => {
  const history = useHistory();
  const project = localStorage.getItem("project");

  const user = JSON.parse(localStorage.getItem("user"));
  const tok = localStorage?.getItem("accessToken");
  const { cuurentRound } = CuurentRound();
  const [totalRound, setTotalRound] = useState([]);
  const [showfilter, setShowfilter] = useState(false);
  const [addLeaderBool, setAddLeadBool] = useState(false);
  const handleClosefilter = () => setShowfilter(false);
  const handleShowfilter = () => {
    setShowfilter(true);
    firstTimeData();
  };

  const [selectedRounds, setSelectedRounds] = useState([]);

  const [leadersLoader, setLeadersLoader] = useState(false);
  const [show, setShow] = useState(false);

  const [blockUnblock, setBlockUnblock] = useState(false);
  const [codeBlockFilter, setCodeBlockFilter] = useState(true);
  const [deletedFilter, setDeletedFilter] = useState(false);

  // const userObj = JSON.parse(localStorage.getItem("user"));

  const [agents, setAgents] = useState([]);

  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(10);

  const [count, setCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const [pages, setPages] = useState([]);
  const [page, setPage] = useState(0);
  const [search0, setSearch0] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [leaderNames, setLeaderNames] = useState([]);
  const [leaderNames2, setLeaderNames2] = useState([]);
  const [leaderFilter, setLeaderFilter] = useState("");
  const [activeFilter, setActiveFilter] = useState(false);
  const [inActiveFilter, setInActiveFilter] = useState(false);
  const [roleTypeFilter, setRoleTypeFilter] = useState("");

  const [searchQuery, setSearchQuery] = useState("");
  const [myTree, setMyTree] = useState(false);

  const [orderField, setOrderField] = useState("directAmountInUsd");
  const [orderDirection, setOrderDirection] = useState("-1");

  const [search, setSearch] = useState("");
  const [searchBy, setSearchBy] = useState("name");

  const [roundFilter, setRoundFilter] = useState([]);
  const [leaderNameFilter, setLeaderNameFilter] = useState("");

  const [saleTypeFilter, setSaleTypeFilter] = useState("");

  const [typeFilter, setTypeFilter] = useState("");

  const handleRemoveRound = (round) => {
    setRoundFilter(
      roundFilter.filter((selectedRound) => selectedRound !== round)
    );
  };

  const [checkboxes, setCheckboxes] = useState({
    ch1: false,
    ch2: false,
    ch3: false,
  });

  const firstTimeData = async () => {
    try {
      let b = await cuurentRound();
      if (b) {
        const result = [];
        for (let i = 1; i <= b; i++) {
          result.push(i);
        }
        setTotalRound(result);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    getLeaderNames();
    // getTomiPrice();
  }, []);

  useEffect(() => {
    if (user) {
      GetAgents();
    }
  }, [
    activeFilter,
    inActiveFilter,
    leaderFilter,
    roleTypeFilter,
    roundFilter.length,
    // saleTypeFilter,
    searchQuery,
    orderField,
    orderDirection,
    codeBlockFilter,
    deletedFilter,
    page,
    addLeaderBool,
  ]);

  const handlePageChange = (e) => {
    const selectedPage = e.selected;

    setPage(selectedPage);
  };

  // useEffect(() => {
  //   if (leaderFilter !== "") {
  //     GetAgents();
  //   }
  // }, [leaderFilter]);

  const getLeaderNames = () => {
    const val = localStorage.getItem("accessToken");

    var config = {
      method: "get",
      url: `${Api_Url}/users/leaders-names?limit=${2000}&privateSale=DOP`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    axios(config)
      .then(function (response) {
        setLeaderNames(response?.data?.data);
        setLeaderNames2(response?.data?.data);
      })
      .catch(function (error) {
        if (error?.response?.status == 401) {
          // localStorage.removeItem("accessToken");
          // history.push("/");
        }
      });
  };

  const GetAgents = (filter) => {
    setAgents([]);
    setLeadersLoader(true);
    const val = localStorage.getItem("accessToken");

    var config = {
      method: "get",
      url: `${Api_Url}/users/leaders?limit=${limit}&offset=${
        page + 1
      }&privateSale=DOP`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    let dynamicQuery = config.url;
    if (leaderFilter) {
      dynamicQuery += `&name=${encodeURIComponent(leaderFilter)}`;
    }

    if (roleTypeFilter) {
      dynamicQuery += `&leaderType=${encodeURIComponent(roleTypeFilter)}`;
    }
    if (roundFilter?.length > 0) {
      dynamicQuery += `&round=${[roundFilter]}`;
    }
    // if (saleTypeFilter) {
    //   dynamicQuery += `&salesType=${saleTypeFilter}`;
    // }
    if (activeFilter) {
      dynamicQuery += `&active=${activeFilter}`;
    }
    if (inActiveFilter) {
      dynamicQuery += `&inActive=${inActiveFilter}`;
    }

    if (searchQuery) {
      dynamicQuery += `&${searchBy}=${searchQuery}`;
    }
    if (orderField) {
      dynamicQuery += `&orderField=${orderField}&orderDirection=${orderDirection}`;
    }
    // if (user?.role == "god_agent") {
    //   dynamicQuery += `&myTree=${myTree}`;
    // }
    config.url = dynamicQuery;

    axios(config)
      .then(function (response) {
        const resData = response?.data?.data;
        setCount(response.data.data.count);
        setAgents(resData?.allUsersData);
        let arr = Array.from(Array(parseInt(response.data.data.pages)).keys());
        setPageCount(response.data.data.pages);
        setPages(arr);

        setLeadersLoader(false);
      })
      .catch(function (error) {
        if (error?.response?.status == 401) {
          // localStorage.removeItem("accessToken");
          history.push("/login");
        }
        setLeadersLoader(false);
      });
  };

  const GetSearch = (searchKey) => {
    setSearch0(searchKey);
    if (searchKey === "") {
      setLeaderFilter("");
      setLeaderNames(leaderNames2);
    } else if (searchKey?.length > 1) {
      const results = leaderNames2.filter((item) =>
        item.name?.toLowerCase().includes(searchKey?.toLowerCase())
      );
      setLeaderNames(results);
      // SearchByName(searchKey);
    }
  };
  return (
    <>
      <Header setAddLeadBool={setAddLeadBool} addLeaderBool={addLeaderBool} />
      <section className="all-leaders">
        <div className="theme-container">
          <div className="upper-heading">
            <h6>all leaders</h6>
            <div className="right-content">
              <div className="option-field">
                <Dropdown className="inputdrop">
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {searchBy == "name"
                      ? "Name"
                      : searchBy == "accessCodeFilter"
                      ? "Access Code"
                      : searchBy == "walletFilter"
                      ? "Wallet"
                      : ""}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13"
                      height="6"
                      viewBox="0 0 13 6"
                      fill="none"
                    >
                      <path
                        d="M0 0L2.22397e-07 2.5462L6.2 6L6.2 3.4538L0 0Z"
                        fill="#02171D"
                      />
                      <path
                        d="M12.3984 0L12.3984 2.5462L6.19844 6L6.19844 3.4538L12.3984 0Z"
                        fill="#02171D"
                      />
                    </svg>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <a
                      className="dropdown-item drop"
                      onClick={() => setSearchBy("name")}
                    >
                      Name
                    </a>
                    <a
                      className="dropdown-item drop"
                      onClick={() => setSearchBy("accessCodeFilter")}
                    >
                      Access Code
                    </a>
                    <a
                      className="dropdown-item drop"
                      onClick={() => setSearchBy("walletFilter")}
                    >
                      Wallet Address
                    </a>
                  </Dropdown.Menu>
                </Dropdown>
                <input
                  type="text"
                  placeholder="Search..."
                  className="paddiinput"
                  onChange={(e) => setSearchQuery(e.target.value)}
                  value={searchQuery}
                />
                <img
                  src="\assets\transaction\search-icon.svg"
                  alt="img"
                  className="img-fluid search-icon"
                />
              </div>
              <button onClick={handleShowfilter} className="filter-btnnn">
                <img
                  src="\assets\transaction\filter.svg"
                  alt="img"
                  className="img-fluid"
                />
                Filters
              </button>
            </div>
          </div>
          <div className="bottom-content">
            {roundFilter?.length > 0 ? (
              <div className="inner-single">
                <p>Rounds</p>
                <div className="multi-items">
                  {roundFilter.map((round) => {
                    return (
                      <h6 key={round}>
                        {round}{" "}
                        <svg
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setRoundFilter(
                              roundFilter.filter(
                                (selectedRound) => selectedRound !== round
                              )
                            );
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <path
                            d="M5.14282 12.8569L12.8571 5.14258"
                            stroke="white"
                            stroke-width="1.2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M12.8571 12.8569L5.14282 5.14258"
                            stroke="white"
                            stroke-width="1.2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </h6>
                    );
                  })}
                </div>
              </div>
            ) : (
              ""
            )}
            {saleTypeFilter ? (
              <div className="inner-single">
                <p>Direct/Indirect Sale</p>
                <div className="multi-items">
                  <h6>
                    {saleTypeFilter == "direct"
                      ? "Direct Sales"
                      : saleTypeFilter == "indirect"
                      ? "Indirect Sales"
                      : ""}{" "}
                    <svg
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setSaleTypeFilter("");
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M5.14282 12.8569L12.8571 5.14258"
                        stroke="white"
                        stroke-width="1.2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12.8571 12.8569L5.14282 5.14258"
                        stroke="white"
                        stroke-width="1.2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </h6>
                </div>
              </div>
            ) : (
              ""
            )}

            {leaderFilter ? (
              <div className="inner-single">
                <p>By Leader Name</p>
                <div className="multi-items">
                  <h6>
                    {leaderFilter}
                    <svg
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setLeaderFilter("");
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M5.14282 12.8569L12.8571 5.14258"
                        stroke="white"
                        stroke-width="1.2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12.8571 12.8569L5.14282 5.14258"
                        stroke="white"
                        stroke-width="1.2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </h6>
                </div>
              </div>
            ) : (
              ""
            )}
            {roleTypeFilter ? (
              <div className="inner-single">
                <p>Role</p>
                <div className="multi-items">
                  <h6>
                    {roleTypeFilter == "god_agent"
                      ? "God Leader "
                      : roleTypeFilter == "mega_agent"
                      ? "Mega Leader "
                      : roleTypeFilter == "super_agent"
                      ? "Super Leader "
                      : roleTypeFilter == "agent"
                      ? "Leader "
                      : roleTypeFilter == "mini_agent"
                      ? "Ambassador "
                      : "Role "}
                    <svg
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setRoleTypeFilter("");
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M5.14282 12.8569L12.8571 5.14258"
                        stroke="white"
                        stroke-width="1.2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12.8571 12.8569L5.14282 5.14258"
                        stroke="white"
                        stroke-width="1.2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </h6>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <Datatable
            btcPrice={btcPrice}
            usdPrice={usdPrice}
            tomiPrice={tomiPrice}
            currentPage={currentPage}
            GetAllRounds={GetAgents}
            pageCount={pageCount}
            pages={pages}
            GetAgents={GetAgents}
            page={page}
            offset={offset}
            setOffset={setOffset}
            limit={limit}
            count={count}
            agents={agents}
            setAgents={setAgents}
            setOrderDirection={setOrderDirection}
            setOrderField={setOrderField}
            orderDirection={orderDirection}
            orderField={orderField}
            handlePageChange={handlePageChange}
            pagination={true}
            leadersLoader={leadersLoader}
            tok={tok}
            addLeaderBool={addLeaderBool}
            setAddLeadBool={setAddLeadBool}
          />
        </div>
      </section>

      <Footerline />

      <Offcanvas
        placement="end"
        className="filter-offcanvas"
        show={showfilter}
        onHide={handleClosefilter}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Filters</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="uppper-area">
            <div class="dropdown round-dropdown">
              <button
                class="dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Rounds
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="8"
                  viewBox="0 0 15 8"
                  fill="none"
                >
                  <path
                    d="M0.535156 0.5L0.535157 3.47056L7.76848 7.5L7.76848 4.52943L0.535156 0.5Z"
                    fill="black"
                  />
                  <path
                    d="M15 0.500122L15 3.47068L7.76667 7.50012L7.76667 4.52955L15 0.500122Z"
                    fill="black"
                  />
                </svg>
              </button>
              <ul class="dropdown-menu">
                <li>
                  <a
                    class="dropdown-item"
                    // onClick={() => handleRoundSelection("All Rounds")}
                  >
                    <div className="checkboxmain">
                      <div class="content">
                        <label class="checkBox">
                          <input
                            type="checkbox"
                            id="chAll"
                            checked={roundFilter.length === totalRound.length}
                            onChange={(e) => {
                              if (e.target.checked) {
                                const allRounds = totalRound.map(
                                  (_, index) => index + 1
                                );
                                setRoundFilter(allRounds);
                              } else {
                                setRoundFilter([]);
                              }
                            }}
                          />
                          <div class="transition"></div>
                        </label>
                      </div>
                      <p className="checkboxpara">All Rounds</p>
                    </div>
                  </a>
                </li>
                {totalRound?.map((elem, index) => {
                  return (
                    <li key={index}>
                      <a
                        class="dropdown-item"
                        // onClick={() => {
                        //   setRoundFilter(index + 1);
                        // }}
                        // onClick={() => handleRoundSelection("All Rounds")}
                      >
                        <div className="checkboxmain">
                          <div class="content">
                            <label class="checkBox">
                              <input
                                type="checkbox"
                                id={`ch${index + 1}`}
                                checked={roundFilter.includes(index + 1)}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    const rounds = [...roundFilter, index + 1];
                                    console.log("rounds: ", rounds);
                                    setRoundFilter(rounds);
                                  } else {
                                    const rounds = roundFilter.filter(
                                      (round) => round !== index + 1
                                    );
                                    console.log("rounds: ", rounds);
                                    setRoundFilter(rounds);
                                  }
                                }}
                              />
                              <div class="transition"></div>
                            </label>
                          </div>
                          <p className="checkboxpara">Round {index + 1}</p>
                        </div>
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="ifroundselect">
              {roundFilter.map((round) => (
                <h6 key={round}>
                  {round}{" "}
                  <svg
                    style={{ cursor: "pointer" }}
                    onClick={() => handleRemoveRound(round)}
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M5.14285 12.8571L12.8571 5.14282"
                      stroke="white"
                      stroke-width="1.2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12.8571 12.8571L5.14285 5.14282"
                      stroke="white"
                      stroke-width="1.2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </h6>
              ))}
            </div>
            {user?.role !== "mini_agent" && user?.role !== "agent" && (
              <>
                <div>
                  <div class="dropdown dropdownbysearch">
                    <button
                      class="dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      By Leader Name
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="9"
                        height="6"
                        viewBox="0 0 9 6"
                        fill="none"
                      >
                        <path
                          d="M8.30454 0H4.29972H0.693464C0.0763497 0 -0.232207 0.910697 0.204915 1.44455L3.53476 5.51129C4.06831 6.1629 4.93612 6.1629 5.46967 5.51129L6.73604 3.96467L8.79952 1.44455C9.23021 0.910697 8.92165 0 8.30454 0Z"
                          fill="black"
                        />
                      </svg>
                    </button>
                    <ul class="dropdown-menu gycytctdtrctrc">
                      <div className="custom-option-field drop-in-search-sticky">
                        <input
                          value={search0}
                          type="search"
                          placeholder="Search"
                          onChange={(e) => GetSearch(e.target.value)}
                        />

                        <img
                          style={{ cursor: "pointer" }}
                          src="\assets\transaction\search-icon.svg"
                          alt="img"
                          className="img-fluid search-icon"
                        />
                      </div>

                      {leaderNames?.length > 0
                        ? leaderNames?.map((item) => {
                            return (
                              <>
                                <li>
                                  <a
                                    class="dropdown-item"
                                    href="#"
                                    onClick={() => {
                                      setLeaderFilter(item?.name);
                                      setSearch0(item?.name);
                                    }}
                                  >
                                    {item?.name}
                                  </a>
                                </li>
                              </>
                            );
                          })
                        : null}
                    </ul>
                  </div>
                  <div className="ifroundselect">
                    {leaderFilter != "" ? (
                      <h6>
                        {leaderFilter}
                        <svg
                          style={{ cursor: "pointer" }}
                          onClick={() => setLeaderFilter("")}
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <path
                            d="M5.14285 12.8571L12.8571 5.14282"
                            stroke="white"
                            stroke-width="1.2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M12.8571 12.8571L5.14285 5.14282"
                            stroke="white"
                            stroke-width="1.2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </h6>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div>
                  <div>
                    <div className="dropdown round-dropdown role-dropdown">
                      <button
                        className="dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {roleTypeFilter == "god_agent"
                          ? "God Leader "
                          : roleTypeFilter == "mega_agent"
                          ? "Mega Leader "
                          : roleTypeFilter == "super_agent"
                          ? "Super Leader "
                          : roleTypeFilter == "agent"
                          ? "Leader "
                          : roleTypeFilter == "mini_agent"
                          ? "Ambassador "
                          : "Role "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="9"
                          height="6"
                          viewBox="0 0 9 6"
                          fill="none"
                        >
                          <path
                            d="M8.30454 0H4.29972H0.693464C0.0763497 0 -0.232207 0.910697 0.204915 1.44455L3.53476 5.51129C4.06831 6.1629 4.93612 6.1629 5.46967 5.51129L6.73604 3.96467L8.79952 1.44455C9.23021 0.910697 8.92165 0 8.30454 0Z"
                            fill="black"
                          />
                        </svg>
                      </button>

                      {user?.role == "admin" ? (
                        <ul class="dropdown-menu">
                          <li>
                            <a
                              onClick={() => setRoleTypeFilter("")}
                              class="dropdown-item"
                              href="#"
                            >
                              All Leaders
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="#"
                              onClick={() => {
                                setRoleTypeFilter("god_agent");
                              }}
                            >
                              God
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="#"
                              onClick={() => {
                                setRoleTypeFilter("mega_agent");
                              }}
                            >
                              Mega
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="#"
                              onClick={() => {
                                setRoleTypeFilter("super_agent");
                              }}
                            >
                              Super
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="#"
                              onClick={() => {
                                setRoleTypeFilter("agent");
                              }}
                            >
                              Leader
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="#"
                              onClick={() => {
                                setRoleTypeFilter("mini_agent");
                              }}
                            >
                              Ambassador
                            </a>
                          </li>
                        </ul>
                      ) : user?.role == "god_agent" ? (
                        <ul class="dropdown-menu">
                          <li>
                            <a
                              onClick={() => setRoleTypeFilter("")}
                              class="dropdown-item"
                              href="#"
                            >
                              All Leaders
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="#"
                              onClick={() => {
                                setRoleTypeFilter("mega_agent");
                              }}
                            >
                              Mega
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="#"
                              onClick={() => {
                                setRoleTypeFilter("super_agent");
                              }}
                            >
                              Super
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="#"
                              onClick={() => {
                                setRoleTypeFilter("agent");
                              }}
                            >
                              Leader
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="#"
                              onClick={() => {
                                setRoleTypeFilter("mini_agent");
                              }}
                            >
                              Ambassador
                            </a>
                          </li>
                        </ul>
                      ) : user?.role == "mega_agent" ? (
                        <ul class="dropdown-menu">
                          <li>
                            <a
                              onClick={() => setRoleTypeFilter("")}
                              class="dropdown-item"
                              href="#"
                            >
                              All Leaders
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="#"
                              onClick={() => {
                                setRoleTypeFilter("super_agent");
                              }}
                            >
                              Super
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="#"
                              onClick={() => {
                                setRoleTypeFilter("agent");
                              }}
                            >
                              Leader
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="#"
                              onClick={() => {
                                setRoleTypeFilter("mini_agent");
                              }}
                            >
                              Ambassador
                            </a>
                          </li>
                        </ul>
                      ) : user?.role == "super_agent" ? (
                        <ul class="dropdown-menu">
                          <li>
                            <a
                              onClick={() => setRoleTypeFilter("")}
                              class="dropdown-item"
                              href="#"
                            >
                              All Leaders
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="#"
                              onClick={() => {
                                setRoleTypeFilter("agent");
                              }}
                            >
                              Leader
                            </a>
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="#"
                              onClick={() => {
                                setRoleTypeFilter("mini_agent");
                              }}
                            >
                              Ambassador
                            </a>
                          </li>
                        </ul>
                      ) : (
                        ""
                      )}

                      {/* <ul className="dropdown-menu">
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => {
                          setRoleTypeFilter("god_agent");
                        }}
                      >
                        God
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => {
                          setRoleTypeFilter("mega_agent");
                        }}
                      >
                        Mega
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => {
                          setRoleTypeFilter("super_agent");
                        }}
                      >
                        Super
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => {
                          setRoleTypeFilter("agent");
                        }}
                      >
                        Leader
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => {
                          setRoleTypeFilter("mini_agent");
                        }}
                      >
                        Ambassador
                      </a>
                    </li>
                  </ul> */}
                    </div>
                    <div className="ifroundselect">
                      {roleTypeFilter != "" ? (
                        <h6>
                          {roleTypeFilter == "god_agent"
                            ? "God Leader "
                            : roleTypeFilter == "mega_agent"
                            ? "Mega Leader "
                            : roleTypeFilter == "super_agent"
                            ? "Super Leader "
                            : roleTypeFilter == "agent"
                            ? "Leader "
                            : roleTypeFilter == "mini_agent"
                            ? "Ambassador "
                            : ""}
                          <svg
                            style={{ cursor: "pointer" }}
                            onClick={() => setRoleTypeFilter("")}
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                          >
                            <path
                              d="M5.14285 12.8571L12.8571 5.14282"
                              stroke="white"
                              stroke-width="1.2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M12.8571 12.8571L5.14285 5.14282"
                              stroke="white"
                              stroke-width="1.2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </h6>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}

            {/* <div class="dropdown round-dropdown">
              <button
                class="dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Sale Type
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9"
                  height="6"
                  viewBox="0 0 9 6"
                  fill="none"
                >
                  <path
                    d="M8.30454 0H4.29972H0.693464C0.0763497 0 -0.232207 0.910697 0.204915 1.44455L3.53476 5.51129C4.06831 6.1629 4.93612 6.1629 5.46967 5.51129L6.73604 3.96467L8.79952 1.44455C9.23021 0.910697 8.92165 0 8.30454 0Z"
                    fill="black"
                  />
                </svg>
              </button>
              <ul class="dropdown-menu">
                
                <li>
                  <a class="dropdown-item">
                    <div className="checkboxmain">
                      <div class="content">
                        <label class="checkBox">
                          <input
                            type="checkbox"
                            id="chDirectSale"
                            checked={saleTypeFilter == "direct"}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSaleTypeFilter("direct");
                              } else {
                                setSaleTypeFilter(
                                  saleTypeFilter.filter(
                                    (type) => type !== "direct"
                                  )
                                );
                              }
                            }}
                          />
                          <div class="transition"></div>
                        </label>
                      </div>
                      <p className="checkboxpara">Direct Sale</p>
                    </div>
                  </a>
                </li>
                <li>
                  <a class="dropdown-item">
                    <div className="checkboxmain">
                      <div class="content">
                        <label class="checkBox">
                          <input
                            type="checkbox"
                            id="chIndirectSale"
                            checked={saleTypeFilter == "indirect"}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSaleTypeFilter("indirect");
                              } else {
                                setSaleTypeFilter(
                                  saleTypeFilter.filter(
                                    (type) => type !== "indirect"
                                  )
                                );
                              }
                            }}
                          />
                          <div class="transition"></div>
                        </label>
                      </div>
                      <p className="checkboxpara">Indirect Sale</p>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <div className="ifroundselect">
             
              {saleTypeFilter != "" ? (
                <h6>
                  {saleTypeFilter == "direct"
                    ? "Direct Sales"
                    : saleTypeFilter == "indirect"
                    ? "Indirect Sales"
                    : ""}{" "}
                  <svg
                    style={{ cursor: "pointer" }}
                    onClick={() => setSaleTypeFilter("")}
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M5.14285 12.8571L12.8571 5.14282"
                      stroke="white"
                      stroke-width="1.2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12.8571 12.8571L5.14285 5.14282"
                      stroke="white"
                      stroke-width="1.2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </h6>
              ) : (
                ""
              )}
              
            </div> */}
          </div>
          <div className="bottom-btns">
            <button
              className="btn-clearall"
              onClick={() => {
                setSearch("");
                setRoundFilter([]);
                setLeaderNameFilter("");
                setLeaderFilter("");
                setRoleTypeFilter("");
                setSaleTypeFilter("");
                setLeaderFilter("");
              }}
            >
              Clear all
            </button>
            <button
              className="btn-applyy"
              onClick={() => {
                GetAgents();
                handleClosefilter();
              }}
            >
              Apply
            </button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Leaders;
