import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getGemsSubscriptionContract } from "../../utils/contractHelpers";

const StakeGems = () => {
  const web3 = useWeb3();
  const tokenAddress = environment.GemsSubscription;
  const contract = getGemsSubscriptionContract(tokenAddress, web3);
  const stakeGems = useCallback(
    async (postApiRes, account) => {
      // let nd = 1000000000000000000000;
      // nd = web3.utils.toWei(nd.toString(), "ether");
      try {
        // let amnt = amount * 10 ** 18;

        // const gas = await contract.methods
        //   .stake(nd)
        //   .estimateGas({ from: account });
        // const details = await contract.methods.stake(nd?.toString()).send({
        //   from: account,
        //   gas,
        // });

        const details = await contract.methods
          .subscribe(
            postApiRes?.price,
            postApiRes?.deadline,
            postApiRes?.normalizationFactor,
            postApiRes?.v,
            postApiRes?.r,
            postApiRes?.s
          )
          .send({
            from: account,
            // gas,
          });

        const transactionHash = details.transactionHash;
        console.log("Transaction Hash:", transactionHash);

        const receipt = await web3.eth.getTransactionReceipt(transactionHash);
        console.log("Transaction receipt:", receipt);

        const blockNumber = receipt.blockNumber;
        console.log("Transaction block number:", blockNumber);

        const block = await web3.eth.getBlock(blockNumber);
        console.log("Transaction block :", block);

        const timestamp = block.timestamp;

        console.log("Transaction Timestamp:", timestamp);

        return { details, transactionHash, timestamp };
      } catch (error) {
        throw error;
      }
    },
    [contract]
  );
  return { stakeGems: stakeGems };
};
export default StakeGems;
