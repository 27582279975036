import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import {
  getclaimscontract,
  getclaimsnewcontract,
  getGemsClaimsContract,
  getIncentivClaimsContract,
  getLEIAClaimsContract,
  getOlympusClaimsContract,
  getNFAClaimsContract,
  getAstroNovaClaimsContract,
  getRainClaimsContract,
} from "../../utils/contractHelpers";
import { getClaimNewLatest } from "../../utils/contractHelpers";
const ClaimInsurance = () => {
  const web3 = useWeb3();

  const incentivAddress = environment.IncentivClaim;
  const LEIAAddress = environment.LEIAClaim;
  const OlympusAddress = environment.OlympusClaim;
  const NFAAddress = environment.NFAClaim;
  const AstroNovaAddress = environment.AstroNovaClaim;
  const RainAddress = environment.RainClaim;

  const incentivContract = getIncentivClaimsContract(incentivAddress, web3);
  const LEIAContract = getLEIAClaimsContract(LEIAAddress, web3);
  const OlympusContract = getOlympusClaimsContract(OlympusAddress, web3);
  const NFAContract = getNFAClaimsContract(NFAAddress, web3);
  const AstroNovaContract = getAstroNovaClaimsContract(AstroNovaAddress, web3);
  const RainContract = getRainClaimsContract(RainAddress, web3);

  const project = localStorage.getItem("project");
  const claimInsurance = useCallback(
    async (account, index) => {
      if (project == "INCENTIV") {
        try {
          const gas = await incentivContract.methods
            .claimInsurance(index)
            .estimateGas({ from: account });

          const details = await incentivContract.methods
            .claimInsurance(index)
            .send({
              from: account,
              gas,
            });
          return details;
        } catch (error) {
          throw error;
        }
      } else if (project == "NFA") {
        try {
          const gas = await NFAContract.methods
            .claimInsurance(index)
            .estimateGas({ from: account });
          const details = await NFAContract.methods.claimInsurance(index).send({
            from: account,
            gas,
          });
          return details;
        } catch (error) {
          throw error;
        }
      } else if (project == "ASTRANOVA") {
        try {
          const gas = await AstroNovaContract.methods
            .claimInsurance(index)
            .estimateGas({ from: account });
          const details = await AstroNovaContract.methods
            .claimInsurance(index)
            .send({
              from: account,
              gas,
            });
          return details;
        } catch (error) {
          throw error;
        }
      } else if (project == "RAIN") {
        try {
          const gas = await RainContract.methods
            .claimInsurance(index)
            .estimateGas({ from: account });
          const details = await RainContract.methods
            .claimInsurance(index)
            .send({
              from: account,
              gas,
            });
          return details;
        } catch (error) {
          throw error;
        }
      } else if (project == "LEIA") {
        try {
          const gas = await LEIAContract.methods
            .claimInsurance(index)
            .estimateGas({ from: account });
          const details = await LEIAContract.methods
            .claimInsurance(index)
            .send({
              from: account,
              gas,
            });
          return details;
        } catch (error) {
          throw error;
        }
      } else if (project == "OLYMPUS") {
        try {
          const gas = await OlympusContract.methods
            .claimInsurance(index)
            .estimateGas({ from: account });
          const details = await OlympusContract.methods
            .claimInsurance(index)
            .send({
              from: account,
              gas,
            });
          return details;
        } catch (error) {
          throw error;
        }
      }
    },
    [incentivContract, LEIAContract]
  );
  return { claimInsurance: claimInsurance };
};
export default ClaimInsurance;
