import web3NoAccount from "./web3";
import tomiContract from "./tomiAbi2.json";
import createRoundDop from "./doproundabi.json";
import insitutionPreSale from "./institutionPreSaleAbi.json";

import createRoundDopOld from "./doproundabiOld.json";

import createRoundNft from "./nftroundabi.json";
import ERC20Token from "./erc20TokenABI.json";
import createRoundDopTomi from "./doptomiroundabi.json";
import createRoundNftTomi from "./nfttomiroundabi.json";
import Claims from "./Claims.json";
import ClaimsNew from "./ClaimsNew.json";
import ClaimsTomi from "./claimsTomi.json";
import ClaimNewLatest from "./claimNewLatest.json";
import GemsClaim from "./GemsClaims.json";
import GemsPreSale from "./GemsPreSale.json";

import IncentivClaim from "./IncentivClaims.json";
import IncentivPreSale from "./IncentivPreSale.json";

import LEIAClaim from "./LEIAClaims.json";
import LEIAPreSale from "./LEIAPreSale.json";
import LEIAInsurance from "./LEIAInsurance.json";

import OlympusClaim from "./OlympusClaims.json";
import OlympusPreSale from "./OlympusPreSale.json";
import OlympusInsurance from "./OlympusInsurance.json";

import NFAClaim from "./NFAClaims.json";
import NFAPreSale from "./NFAPreSale.json";
import NFAInsurance from "./NFAInsurance.json";

import AstroNovaPresale from "./AstroNovaPreSale.json";
import AstroNovaClaims from "./AstroNovaClaims.json";

import RainPresale from "./RainPreSale.json";
import RainClaims from "./RainClaims.json";

import RainPresaleV2 from "./RainPreSaleV2.json";
import RainClaimsV2 from "./RainClaimsV2.json";

import RainPresaleV3 from "./RainPreSaleV3.json";
import RainClaimsV3 from "./RainClaimsV3.json";

import MinersClaims from "./MinersClaims.json";
import MinersClaimsV2 from "./MinersClaimsV2.json";

import MinersClaimsOld from "./MinersClaimsOld.json";

import GemsStaking from "./GemsStakingABI.json";
import GemsSubscription from "./GemsSubscriptionABI.json";

import gemsTokenContract from "./gemsTokenABI.json";
const getContract = (abi, address, web3) => {
  const _web3 = web3 ?? web3NoAccount;
  return new _web3.eth.Contract(abi, address);
};

export const getTomiContract = (address, web3) => {
  return getContract(tomiContract, address, web3);
};

export const getdoproundcreatecontract = (address, web3) => {
  return getContract(createRoundDop, address, web3);
};

export const getinstitutionpresalecontract = (address, web3) => {
  return getContract(insitutionPreSale, address, web3);
};

export const getdoproundcreatecontractold = (address, web3) => {
  return getContract(createRoundDopOld, address, web3);
};
export const getnftroundcreatecontract = (address, web3) => {
  return getContract(createRoundNft, address, web3);
};
export const getdoptomiroundcreatecontract = (address, web3) => {
  return getContract(createRoundDopTomi, address, web3);
};
export const getnfttomiroundcreatecontract = (address, web3) => {
  return getContract(createRoundNftTomi, address, web3);
};
export const getclaimscontract = (address, web3) => {
  return getContract(Claims, address, web3);
};
export const getclaimsnewcontract = (address, web3) => {
  return getContract(ClaimsNew, address, web3);
};
export const getclaimstomicontract = (address, web3) => {
  return getContract(ClaimsTomi, address, web3);
};
export const getClaimNewLatest = (address, web3) => {
  return getContract(ClaimNewLatest, address, web3);
};

export const getERC20Token = (address, web3) => {
  return getContract(ERC20Token, address, web3);
};
////////////////////////////////////////////////////// GEMS

export const getGemsPreSaleContract = (address, web3) => {
  return getContract(GemsPreSale, address, web3);
};

export const getGemsClaimsContract = (address, web3) => {
  return getContract(GemsClaim, address, web3);
};

export const getIncentivPreSaleContract = (address, web3) => {
  return getContract(IncentivPreSale, address, web3);
};

export const getIncentivClaimsContract = (address, web3) => {
  return getContract(IncentivClaim, address, web3);
};

export const getLEIAPreSaleContract = (address, web3) => {
  return getContract(LEIAPreSale, address, web3);
};

export const getLEIAClaimsContract = (address, web3) => {
  return getContract(LEIAClaim, address, web3);
};

export const getLEIAInsuranceContract = (address, web3) => {
  return getContract(LEIAInsurance, address, web3);
};

export const getOlympusPreSaleContract = (address, web3) => {
  return getContract(OlympusPreSale, address, web3);
};

export const getOlympusClaimsContract = (address, web3) => {
  return getContract(OlympusClaim, address, web3);
};

export const getOlympusInsuranceContract = (address, web3) => {
  return getContract(OlympusInsurance, address, web3);
};

export const getNFAPreSaleContract = (address, web3) => {
  return getContract(NFAPreSale, address, web3);
};

export const getNFAClaimsContract = (address, web3) => {
  return getContract(NFAClaim, address, web3);
};

export const getNFAInsuranceContract = (address, web3) => {
  return getContract(NFAInsurance, address, web3);
};

export const getAstroNovaPreSaleContract = (address, web3) => {
  return getContract(AstroNovaPresale, address, web3);
};

export const getAstroNovaClaimsContract = (address, web3) => {
  return getContract(AstroNovaClaims, address, web3);
};

export const getRainPreSaleContract = (address, web3) => {
  return getContract(RainPresale, address, web3);
};

export const getRainClaimsContract = (address, web3) => {
  return getContract(RainClaims, address, web3);
};

export const getRainPreSaleV2Contract = (address, web3) => {
  return getContract(RainPresaleV2, address, web3);
};

export const getRainClaimsV2Contract = (address, web3) => {
  return getContract(RainClaimsV2, address, web3);
};

export const getRainPreSaleV3Contract = (address, web3) => {
  return getContract(RainPresaleV3, address, web3);
};

export const getRainClaimsV3Contract = (address, web3) => {
  return getContract(RainClaimsV3, address, web3);
};

export const getMinersClaimsOldContract = (address, web3) => {
  return getContract(MinersClaimsOld, address, web3);
};

export const getMinersClaimsContract = (address, web3) => {
  return getContract(MinersClaims, address, web3);
};

export const getMinersClaimsV2Contract = (address, web3) => {
  return getContract(MinersClaimsV2, address, web3);
};

export const getGemsStakingContract = (address, web3) => {
  return getContract(GemsStaking, address, web3);
};

export const getGemsSubscriptionContract = (address, web3) => {
  return getContract(GemsSubscription, address, web3);
};

export const getGemsTokenContract = (address, web3) => {
  return getContract(gemsTokenContract, address, web3);
};
