import React, { useEffect } from "react";
import "./staking.scss";
import Datatable from "./DataTable/Datatable";
import { Accordion, Modal } from "react-bootstrap";
import { useState } from "react";
import Header from "../landing/navbar/Header";
import GetGemsBalance from "../../hooks/dataFetchers/getGemsBalance";
import GetGemsStaked from "../../hooks/dataFetchers/getGemsStakedAmount";
import StakeGems from "../../hooks/dataSenders/stakeGems";
import UnStakeGems from "../../hooks/dataSenders/unstakeGems";
import StakeGemsGas from "../../hooks/dataSenders/stakeGemsGas";
import AllowanceGems from "../../hooks/dataFetchers/allowanceGems";
import GetGemsStakedIndex from "../../hooks/dataFetchers/getGemsStakedIndex";

import ApproveGems from "../../hooks/dataSenders/approveGems";
import { useWeb3React } from "@web3-react/core";
import Loader from "../../hooks/loader";
import { toast } from "react-toastify";
import axios from "axios";
import Environment from "../../utils/Environment";
import { Api_Url } from "../../utils/ApiUrl";
import moment from "moment";

const Staking = ({ usdPrice, gemsPrice }) => {
  const user = JSON.parse(localStorage?.getItem("user"));
  // const user = { isPremium: true };

  const { account } = useWeb3React();
  const { GetGemsBal } = GetGemsBalance();
  const { getGemsStaked } = GetGemsStaked();
  const { stakeGems } = StakeGems();
  const { unstakeGems } = UnStakeGems();
  const { stakeGemsGas } = StakeGemsGas();
  const { allowanceGems } = AllowanceGems();
  const { approveGems } = ApproveGems();
  const { getGemsStakedIndex } = GetGemsStakedIndex();

  const [loader, setLoader] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setWalletError("");
  };
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => {
    setShow1(false);
    setAmount("");
  };
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [openWalletModal, setOpenWalletModal] = useState(false);

  const [amount, setAmount] = useState("400");
  const [amountError, setAmountError] = useState("");
  const [walletError, setWalletError] = useState("");

  const [balance, setBalance] = useState(0);
  const [allowance, setAllowance] = useState(0);

  const [minStakeAmount, setMinStakeAmount] = useState(999999999999999);
  const [stakingGasFees, setStakingGasFees] = useState(0);
  const [stakedAmount, setStakedAmount] = useState(0);
  const [subscriptionEndTime, setSubscriptionEndTime] = useState([]);
  const [subscriptionData, setSubscriptionData] = useState([]);

  const [stakeEndTime, setStakeEndTime] = useState([]);
  const [stakingData, setStakingData] = useState([]);
  const [country, setCountry] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [itemsPerPage] = useState(10); // Define how many items to show per page
  const [orderBy, setOrderBy] = useState("blockTimestamp");
  const [orderDirection, setOrderDirection] = useState("asc");

  useEffect(() => {
    if (user) {
      get_ApiKeys();

      getGemsBalanceFunc();
      fetchSubscriptionData();
      fetchStakingData();
      getGemsStakedAmount();
    }
  }, []);

  useEffect(() => {
    if (
      account &&
      account.toLowerCase() == user?.walletAddress?.toLowerCase()
    ) {
      getAllowance();
    }
  }, [account]);

  const getGemsBalanceFunc = async () => {
    const bal = await GetGemsBal(user?.walletAddress);
    if (bal !== null || bal !== undefined) {
      setBalance(bal);
    }
  };

  const getAllowance = async () => {
    const all = await allowanceGems(account);
    if (all !== null || all !== undefined) {
      setAllowance(all);
    }
  };

  const getGemsStakedAmount = async () => {
    const staked = await getGemsStaked(user?.walletAddress);

    if (staked !== null || staked !== undefined) {
      setStakedAmount(staked);
    }
  };

  const get_ApiKeys = async () => {
    // let ipp=localStorage.getItem('ip')
    try {
      const response = await axios({
        method: "get",
        url: "https://ip.nf/me.json",
      });
      //  console.log(ipp,'response', response?.data.ip.ip);
      // if (!ipp||ipp!=response?.data.ip.ip){
      // localStorage.setItem('ip', response?.data.ip.ip)
      get_ApiKeys1(response?.data.ip.ip);
      // }
    } catch (error) {
      // setShowService(false)
      console.log(error, "error");
      // localStorage.setItem('ip',response?.data.ip)
      // localStorage.setItem('country',response?.data.country)
      // throw error;
    }
  };

  const get_ApiKeys1 = async (e) => {
    try {
      const response = await axios({
        method: "get",
        url: `https://api.iplocation.net/?ip=${e}`,
      });
      //  console.log(response?.data.country_name,'sdsdsdsds',);
      // localStorage.setItem('ip', response?.data.ip.ip)
      setCountry(response?.data.country_name?.toLowerCase());
      // setCountryyName(response?.data.ip.country)
      if (
        response?.data.country_name?.toLowerCase()?.includes("southsudan") ||
        response?.data.country_name?.toLowerCase()?.includes("south sudan") ||
        response?.data.country_name?.toLowerCase()?.includes("afghanistan") ||
        response?.data.country_name?.toLowerCase()?.includes("yemen") ||
        response?.data.country_name?.toLowerCase()?.includes("myanmar") ||
        response?.data.country_name?.toLowerCase()?.includes("unitedstates") ||
        response?.data.country_name?.toLowerCase()?.includes("united states") ||
        response?.data.country_name?.toLowerCase()?.includes("iran") ||
        response?.data.country_name?.toLowerCase()?.includes("north korea") ||
        response?.data.country_name?.toLowerCase()?.includes("syria") ||
        response?.data.country_name?.toLowerCase()?.includes("lebanon") ||
        response?.data.country_name?.toLowerCase()?.includes("northkorea") ||
        response?.data.country_name?.toLowerCase()?.includes("america") ||
        response?.data.country_name?.toLowerCase()?.includes("usa")
      ) {
        // setShowService(true)
      }
      //  }
      else {
        // setShowService(false);
      }
    } catch (error) {
      // setShowService(false)
      setCountry("india");

      console.log(error, "error");
      // localStorage.setItem('ip',response?.data.ip)
      // localStorage.setItem('country',response?.data.country)
      // throw error;
    }
  };

  const Stake = async () => {
    console.log("stake func");

    if (account) {
      console.log("stake func account yes");

      if (account.toLowerCase() == user?.walletAddress?.toLowerCase()) {
        console.log(
          "stake func account same: ",
          Environment.gems,
          account,
          country
        );

        let config = {
          method: "post",
          url: `${Api_Url}/users/buy-premium-subscription`,
          data: {
            gemsAddress: Environment.gems,
            walletAddress: account,
            country: country,

            // walletAddress: account.toLocaleLowerCase(),
            // country: countryBuyer,
            // type: "NODE",
            // tokenSelected: selectedToken?.mainTag,
          },
        };
        console.log("stake func api config");
        let postApiRes = await axios(config)
          .then(function (response) {
            return response?.data?.data;
          })
          .catch(function (error) {
            setLoader(false);
            console.error("API Error:", error);
            toast.error(error?.response?.data?.message || "API request failed");
            return null;
          });
        if (postApiRes) {
          if (balance >= amount / gemsPrice) {
            if (allowance >= amount / gemsPrice) {
              try {
                setLoader(true);
                const gas = await stakeGems(postApiRes, account);
                console.log("gas: ", gas);

                if (gas) {
                  stakeAPI(gas?.transactionHash, gas?.timestamp);
                  handleClose();
                  handleShow1();
                  setLoader(false);
                  getGemsStakedAmount();
                  getGemsBalanceFunc();
                  setTimeout(() => {
                    fetchSubscriptionData();
                  }, 5000);

                  // setStakingGasFees(gas);
                  // handleShow();
                }
              } catch (e) {
                setLoader(false);
                console.error(e);
                toast.error(e?.message);
              }
            } else {
              try {
                setLoader(true);

                const approve = await approveGems(account, amount / gemsPrice);
                if (approve) {
                  getAllowance();
                  getGemsBalanceFunc();
                  if (balance >= amount) {
                    try {
                      const gas = await stakeGems(postApiRes, account);
                      console.log("gas: ", gas);
                      if (gas) {
                        stakeAPI(gas?.transactionHash, gas?.timestamp);
                        handleClose();
                        handleShow1();
                        setLoader(false);
                        getGemsStakedAmount();
                        getGemsBalanceFunc();

                        setTimeout(() => {
                          fetchStakingData();
                          fetchSubscriptionData();
                        }, 5000);

                        // setStakingGasFees(gas);
                        // handleShow();
                      }
                    } catch (e) {
                      setLoader(false);
                      console.error(e);
                      toast.error(e?.message);
                    }
                  } else {
                    // handleShow2();
                    setLoader(false);

                    setWalletError("Not Enough GEMS Balance");
                  }
                }
              } catch (e) {
                setLoader(false);
                console.error(e);
                toast.error(e?.message);
              }
            }
          } else {
            // handleShow2();
            setWalletError("Not Enough GEMS Balance");
          }
        }
      } else {
        setWalletError("Incorrect Wallet!");
      }
    } else {
      // setWalletError("Connect Wallet!");
      handleClose();
      setOpenWalletModal(true);
    }
  };

  const unstake = async () => {
    if (
      account &&
      account?.toLowerCase() == user?.walletAddress?.toLowerCase()
    ) {
      setLoader(true);
      try {
        const index = await getGemsStakedIndex(account);
        if (index) {
          try {
            const gas = await unstakeGems(account, parseInt(index) - 1);
            if (gas) {
              // handleShow1();

              setLoader(false);
              getGemsStakedAmount();
              setTimeout(() => {
                fetchStakingData();
                fetchSubscriptionData();
              }, 5000);

              handleShow3();
              // getGemsBalanceFunc();
              // setStakingGasFees(gas);
              // handleShow();
            }
          } catch (e) {
            setLoader(false);
            console.error(e);
            toast.error(e?.message);
          }
        }
      } catch (e) {
        setLoader(false);

        console.error(e);
      }
    } else {
      setAmountError("Incorrect Wallet!");
      toast.error("Please Connect the Leader's Wallet!");
    }
  };

  const calculateStakeGas = async () => {
    if (account.toLowerCase() == user?.walletAddress?.toLowerCase()) {
      setLoader(true);
      if (allowance > amount) {
        try {
          const gas = await stakeGemsGas(account, amount);
          if (gas) {
            setStakingGasFees(gas);
            handleShow();
            setLoader(false);
          }
        } catch (e) {
          setLoader(false);
          console.error(e);
          toast.error(e?.message);
        }
      } else {
        try {
          const approve = await approveGems(account);
          if (approve) {
            getAllowance();

            const gas = await stakeGemsGas(account, amount);

            if (gas) {
              setStakingGasFees(gas);
              handleShow();
              setLoader(false);
            }
          }
        } catch (e) {
          setLoader(false);
          console.error(e);
          toast.error(e?.message);
        }
      }
    } else {
      setAmountError("Incorrect Wallet!");
    }
  };

  const stakeAPI = async (txHash, txTime) => {
    let tok = localStorage.getItem("accessToken");
    var config = {
      method: "patch",
      // url: `${Api_Url}/users/update-leader-plan`,
      url: `${Api_Url}/users/${user?._id}/leader-premium`,
      data: {
        txHash: txHash,
        txTime: txTime?.toString(),
      },
      headers: {
        authorization: `Bearer ` + tok,
      },
    };
    await axios(config)
      .then(function (response) {})
      .catch(function (error) {
        console.error(error?.message);
      });
  };

  const fetchSubscriptionData = async () => {
    setLoader(true);
    var data = JSON.stringify({
      query: `query MyQuery {
  subscribeds(where: {by: "${user?.walletAddress}"}, orderBy: blockTimestamp, orderDirection: desc) {
    blockTimestamp
    blockNumber
    amountPurchased
    by
    endTime
    id
    tokenPrice
    transactionHash
  }
}`,
      variables: {},
    });
    var config = {
      method: "post",
      url: Environment.GemsSubscriptionGraph,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios(config)
      .then(async function (response) {
        const resData = response.data.data.subscribeds;
        if (resData.length > 0) {
          // setStakeIndux1(parseInt(resData[0]?.stakerStakeIndex));
          setSubscriptionData(resData);
          console.log("res data: ", resData);

          const currentTime = moment();
          const endTimeStampInSeconds = parseInt(resData[0]?.endTime);
          // const endTimeStamp = endTimeStampInSeconds * 1000; // Convert to milliseconds
          const endTimeStamp = moment(endTimeStampInSeconds * 1000);

          const utcISO = new Date(endTimeStamp).toISOString();
          console.log("subscription end time: ", currentTime, endTimeStamp);

          if (!endTimeStamp) {
            setSubscriptionEndTime(currentTime);
          } else {
            setSubscriptionEndTime(endTimeStamp);
          }
        }
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
        console.log(error, "error");
      });
  };

  const fetchStakingData = async () => {
    try {
      const response = await axios.post(
        Environment?.GemsStakingGraph,
        {
          query: `
            query MyQuery($staker: String, $isClaimed: Boolean,) {
              stakeds(where: {staker: $staker}, isClaimed: $isClaimed,) {
              
                blockTimestamp
                stakeEndTime
                isClaimed
              }
            }`,
          variables: {
            staker: user?.walletAddress,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const resData = response.data.data.stakeds;
      setStakingData(resData);
      console.log("staaking data: ", resData);

      const currentTime = moment();
      const endTimeStampInSeconds = parseInt(
        resData[resData?.length - 1]?.stakeEndTime
      );
      const endTimeStamp = moment(endTimeStampInSeconds * 1000);
      console.log("stake time: ", currentTime, endTimeStamp);

      if (!endTimeStamp.isValid()) {
        setStakeEndTime(currentTime);
      } else {
        setStakeEndTime(endTimeStamp);
      }
    } catch (error) {
      console.error("Error fetching staking data: ", error);
    }
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;
    if (value === "" || (Number(value) <= balance && Number(value) >= 0)) {
      setAmount(value);
      setAmountError("");
    } else {
      setAmountError("Amount exceeds balance");
    }
  };

  const handleSorting = (sortBy) => {
    setOrderBy(sortBy);
    setOrderDirection(
      orderBy === sortBy && orderDirection === "asc" ? "desc" : "asc"
    );
  };

  const handlePagination = (page) => {
    setCurrentPage(page);
  };

  const [show3, setShow3] = useState(false);

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  return (
    <>
      {loader && <Loader />}

      <Header
        openWalletModal={openWalletModal}
        setOpenWalletModal={setOpenWalletModal}
      />
      <section className="main-staking">
        <div className="upperbanner">
          <div className="theme-container">
            <h6 className="staketoppara">Gems Premium</h6>
            <h4 className="stakemainhead">
              Enjoy 100% of your rewards <span>with Gems Premium!</span>
            </h4>
            <p>Enjoy the full potential of Gems platform for $400/year</p>
          </div>
        </div>
        <div className="lowerbanner">
          <div className="theme-container">
            {/* <div className="lowerinnerbanner">
              <div className="lowerleft">
                <span className="lowerleftshade"></span>
                <h6 className="totalpara">TOTAL GEMS Staked</h6>
                <div className="totalgemsmain">
                  <img
                    src="\assets\gemstoken.svg"
                    alt="gemslogoimg"
                    className="gemslogoimg"
                  />
                  <p className="totalgemspara">
                    {stakedAmount?.toLocaleString()} GEMS
                  </p>
                </div>
                <div className="stakebalance">
                  <p className="stakepara">Stake GEMS</p>
                  <p className="balancepara">
                    <span>Balance:</span>
                    <span>{balance?.toLocaleString()} GEMS</span>
                  </p>
                </div>
                <input
                  type="number"
                  placeholder="Enter amount of GEMS to stake"
                  className="amountinput"
                  value={amount}
                  max={balance}
                  onChange={handleAmountChange}
                />
                <div className="mb-4">
                  <p className="text-danger">{amountError}</p>
                </div>
                <div className="stakingperiodmain">
                  <p className="stakingperiodpara">Staking Period</p>
                  <p className="stakingyearpara">1 Year </p>
                </div>
                <button
                  // onClick={handleShow}
                  className="approvebtn"
                  // disabled={amount < 18000}
                  onClick={() => {
                    if (!account) {
                      // setAmountError("Connect Wallet First!");
                      toast.info("Connect Your Wallet");
                    } else if (amount < 18000) {
                      setAmountError("Cannot stake less than 18000 GEMS!");
                    } else if (balance > amount) {
                      // Stake();
                      calculateStakeGas();
                    } else {
                      setAmountError("Insufficient GEMS Balance!");
                    }
                  }}
                >
                  {allowance > amount ? "Stake" : "Approve"}
                </button>
              </div>
              <div className="lowerright">
                <p className="lowerrightpara">Stake History</p>
                <Datatable
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                  totalItems={totalItems}
                  orderBy={orderBy}
                  setOrderBy={setOrderBy}
                  orderDirection={orderDirection}
                  setOrderDirection={setOrderDirection}
                  setCurrentPage={setCurrentPage}
                  stakingData={stakingData}
                  handlePagination={handlePagination}
                  handleSorting={handleSorting}
                  unstake={unstake}
                />
              </div>
            </div> */}
            <div className="newboxes-twice">
              <div className="single-box">
                <div className="upper-content">
                  <h6>basic</h6>
                  <h4>
                    <span>$0</span>
                  </h4>
                  <p>Your starting point in your GEMS leader journey!</p>
                </div>
                <div className="bottom-content">
                  <div className="inner-single">
                    <div className="text">
                      <img
                        src="\assets\premium\reward.svg"
                        alt="img"
                        className="img-fluid"
                      />
                      <p className="">Rewards</p>
                    </div>
                    {/* <img
                      src="\assets\premium\check.svg"
                      alt="img"
                      className="img-fluid"
                    /> */}
                    <h6>50%</h6>
                  </div>
                  <div className="inner-single">
                    <div className="text">
                      <img
                        src="\assets\premium\addunlimited.svg"
                        alt="img"
                        className="img-fluid"
                      />
                      <p>Can participate in presale</p>
                    </div>
                    <img
                      src="\assets\premium\check.svg"
                      alt="img"
                      className="img-fluid"
                    />
                  </div>
                  <div className="inner-single">
                    <div className="text">
                      <img
                        src="\assets\premium\earn100.svg"
                        alt="img"
                        className="img-fluid"
                      />
                      <p>Presale Discount</p>
                    </div>
                    <img
                      src="\assets\premium\cross.svg"
                      alt="img"
                      className="img-fluid"
                    />
                  </div>
                  <div className="inner-single">
                    <div className="text">
                      <img
                        src="\assets\premium\privaterounddiscount.svg"
                        alt="img"
                        className="img-fluid"
                      />
                      <p>Private Sale Round Discount</p>
                    </div>
                    <img
                      src="\assets\premium\cross.svg"
                      alt="img"
                      className="img-fluid"
                    />
                  </div>
                  <div className="inner-single">
                    <div className="text">
                      <img
                        src="\assets\premium\addleader.svg"
                        alt="img"
                        className="img-fluid"
                      />
                      <p>Add Leaders</p>
                    </div>
                    {/* <img
                      src="\assets\premium\cross.svg"
                      alt="img"
                      className="img-fluid"
                    /> */}
                    <h6>Upto 20</h6>
                  </div>
                  <div className="inner-single">
                    <div className="text">
                      <img
                        src="\assets\premium\priority.svg"
                        alt="img"
                        className="img-fluid"
                      />
                      <p>Customer Support</p>
                    </div>
                    <img
                      src="\assets\premium\cross.svg"
                      alt="img"
                      className="img-fluid"
                    />
                  </div>
                </div>
                {user?.isPremium == true ||
                (stakingData?.length > 0 && stakingData?.isClaimed == false) ||
                subscriptionData?.length > 0 ? null : (
                  <a className="btn-plan">CURRENT PLAN</a>
                )}
              </div>
              <div className="main-bottom-ahead">
                <div className="single-box activee">
                  <span className="gradient-activee"></span>
                  <div className="upper-content">
                    <div className="d-flex justify-content-between align-item-center">
                      <h6 style={{ color: "#E89735" }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="26"
                          viewBox="0 0 26 26"
                          fill="none"
                        >
                          <path
                            d="M20.5832 3.25H5.4165L2.1665 9.75L12.9998 22.75L23.8332 9.75L20.5832 3.25ZM10.4215 8.66667L12.0465 5.41667H13.9532L15.5782 8.66667H10.4215ZM11.9165 10.8333V18.07L5.89317 10.8333H11.9165ZM14.0832 10.8333H20.1065L14.0832 18.07V10.8333ZM20.8648 8.66667H17.994L16.369 5.41667H19.2398L20.8648 8.66667ZM6.75984 5.41667H9.63067L8.00567 8.66667H5.13484L6.75984 5.41667Z"
                            fill="#E89735"
                          />
                        </svg>
                        Gems Premium
                      </h6>
                      {subscriptionData?.length > 0 &&
                      moment().isAfter(subscriptionEndTime) &&
                      stakingData?.length > 0 &&
                      subscriptionEndTime.isAfter(stakeEndTime) ? (
                        <p
                          className="cdhbdybydbcy"
                          style={{ color: "#FF4A4A" }}
                        >
                          Plan expired on{" "}
                          {moment(subscriptionEndTime)?.format("DD/MM/YY")}
                        </p>
                      ) : subscriptionData?.length > 0 &&
                        moment() > subscriptionEndTime &&
                        stakingData?.length > 0 &&
                        moment().isAfter(stakeEndTime) &&
                        stakingData[stakingData.length - 1]?.isClaimed ==
                          false ? (
                        <p
                          className="cdhbdybydbcy"
                          style={{ color: "#FF4A4A" }}
                        >
                          Plan expired on{" "}
                          {moment(stakeEndTime)?.format("DD/MM/YY")}
                        </p>
                      ) : null}
                    </div>
                    <h4>
                      <span>$400</span>{" "}
                      <div className="setpara">
                        <p>/year </p>
                        <p>Paid in $GEMS</p>
                      </div>
                    </h4>
                    <p>Enjoy full benefits of the GEMS platform!</p>
                  </div>
                  <div className="bottom-content">
                    <div className="inner-single">
                      <div className="text">
                        <img
                          src="\assets\premium\reward.svg"
                          alt="img"
                          className="img-fluid"
                        />
                        <p>Rewards</p>
                      </div>
                      {/* <img
                        src="\assets\premium\check.svg"
                        alt="img"
                        className="img-fluid"
                      /> */}
                      <h6>100%</h6>
                    </div>
                    <div className="inner-single">
                      <div className="text">
                        <img
                          src="\assets\premium\canparticipate.svg"
                          alt="img"
                          className="img-fluid"
                        />
                        <p>Can participate in presale</p>
                      </div>
                      <img
                        src="\assets\premium\check.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </div>
                    <div className="inner-single">
                      <div className="text">
                        <img
                          src="\assets\premium\earn100.svg"
                          alt="img"
                          className="img-fluid"
                        />
                        <p>Presale Discount</p>
                      </div>
                      {/* <img
                        src="\assets\premium\check.svg"
                        alt="img"
                        className="img-fluid"
                      /> */}
                      <img
                        src="\assets\premium\cross.svg"
                        alt="img"
                        className="img-fluid"
                      />
                      {/* <h6>20%</h6> */}
                    </div>
                    <div className="inner-single">
                      <div className="text">
                        <img
                          src="\assets\premium\privaterounddiscount.svg"
                          alt="img"
                          className="img-fluid"
                        />
                        <p>Private Sale Round Discount</p>
                      </div>
                      <img
                        src="\assets\premium\cross.svg"
                        alt="img"
                        className="img-fluid"
                      />
                      {/* <h6>2%</h6> */}
                    </div>
                    <div className="inner-single">
                      <div className="text">
                        <img
                          src="\assets\premium\addleader.svg"
                          alt="img"
                          className="img-fluid"
                        />
                        <p>Add Leaders</p>
                      </div>
                      {/* <img
                      src="\assets\premium\cross.svg"
                      alt="img"
                      className="img-fluid"
                    /> */}
                      <h6>Upto 20</h6>
                    </div>
                    <div className="inner-single">
                      <div className="text">
                        <img
                          src="\assets\premium\priority.svg"
                          alt="img"
                          className="img-fluid"
                        />
                        <p>Customer Support</p>
                      </div>
                      <img
                        src="\assets\premium\cross.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </div>
                  </div>

                  {subscriptionData?.length == 0 &&
                  user?.isPremium == false &&
                  stakedAmount < 18000 ? (
                    <button
                      className="btn-plan activee"
                      disabled={
                        stakingData.length > 0 &&
                        stakingData[stakingData.length - 1]?.isClaimed == false
                      }
                      onClick={() => {
                        handleShow();
                      }}
                    >
                      Get Gems Premium
                    </button>
                  ) : (subscriptionData?.length > 0 &&
                      subscriptionEndTime > moment()) ||
                    user?.isPremium == true ||
                    (stakingData?.length > 0 &&
                      moment().isBefore(stakeEndTime)) ? (
                    <a
                      className="btn-plan"
                      style={{ justifyContent: "space-between" }}
                    >
                      <h6>CURRENT PLAN</h6>
                      {stakeEndTime < moment() ? (
                        <p>
                          expires on{" "}
                          {moment(subscriptionEndTime)?.format("DD/MM/YY")}
                        </p>
                      ) : (
                        <p>
                          expires on {moment(stakeEndTime)?.format("DD/MM/YY")}
                        </p>
                      )}
                    </a>
                  ) : stakingData?.length > 0 &&
                    moment().isAfter(stakeEndTime) ? (
                    <div className="twice-btns" style={{ gap: "10px" }}>
                      {/* {stakingData[stakingData.length - 1]?.isClaimed == false && (
                        <a
                          className="withdraw-gems"
                          onClick={() => {
                            unstake();
                          }}
                        >
                          Withdraw 18000 Gems
                        </a>
                      )} */}
                      <button
                        className="upgrade-gems"
                        disabled={
                          stakingData.length > 0 &&
                          stakingData[stakingData.length - 1]?.isClaimed ==
                            false
                        }
                        onClick={() => {
                          handleShow();
                        }}
                      >
                        Get Gems Premium
                      </button>
                    </div>
                  ) : subscriptionData?.length == 0 &&
                    subscriptionEndTime < moment() ? (
                    <div className="twice-btns" style={{ gap: "10px" }}>
                      {/* <a
                        className="withdraw-gems"
                        onClick={() => {
                          unstake();
                        }}
                      >
                        Withdraw Gems
                      </a> */}
                      <a
                        className="upgrade-gems"
                        onClick={() => {
                          handleShow();
                        }}
                      >
                        Upgrade Again
                      </a>
                    </div>
                  ) : null}
                  {stakingData?.length > 0 &&
                    stakingData[stakingData.length - 1]?.isClaimed == false &&
                    moment().isAfter(stakeEndTime) && (
                      <>
                        <Accordion
                          defaultActiveKey="0"
                          className="withdrawaccordion"
                        >
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="26"
                                height="26"
                                viewBox="0 0 26 26"
                                fill="none"
                              >
                                <path
                                  d="M20.5832 3.25H5.4165L2.1665 9.75L12.9998 22.75L23.8332 9.75L20.5832 3.25ZM10.4215 8.66667L12.0465 5.41667H13.9532L15.5782 8.66667H10.4215ZM11.9165 10.8333V18.07L5.89317 10.8333H11.9165ZM14.0832 10.8333H20.1065L14.0832 18.07V10.8333ZM20.8648 8.66667H17.994L16.369 5.41667H19.2398L20.8648 8.66667ZM6.75984 5.41667H9.63067L8.00567 8.66667H5.13484L6.75984 5.41667Z"
                                  fill="#E89735"
                                />
                              </svg>
                              You are already subscribed to Gems Premium
                            </Accordion.Header>
                            <Accordion.Body>
                              <p className="accpara">
                                You will continue to enjoy your Gems Premium
                                benefits as long as your GEMS remain locked.
                              </p>
                              <p className="accpara mb-0">
                                If you choose to withdraw your locked tokens,
                                your Gems Premium benefits will be{" "}
                                <span className="bolder">canceled</span>, and
                                you can{" "}
                                <span className="bolder">
                                  resubscribe at a rate of $400 per year.
                                </span>
                              </p>
                              {/* <button
                          className="dullbtn"
                        >
                          Withdraw 18,000 GEMS
                        </button> */}

                              {stakeEndTime < moment() ? (
                                <button
                                  className="withdrawbtn"
                                  onClick={() => {
                                    unstake();
                                  }}
                                >
                                  Withdraw 18,000 GEMS
                                </button>
                              ) : (
                                <>
                                  <button className="dullbtn">
                                    Withdraw 18,000 GEMS
                                  </button>
                                  <p className="lastparaacc">
                                    Current Plan expires on{" "}
                                    {moment(stakeEndTime)?.format("DD/MM/YY")}
                                  </p>
                                </>
                              )}
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </>
                    )}

                  {/* <a href="#" className="btn-plan" style={{flexDirection: "column"}}>
                    <h6>CURRENT PLAN</h6>
                    <p>expires on 6/07/24</p>
                  </a> */}
                  {/* <div className="twice-btns">
                    <a className="withdraw-gems" onClick={()=>{unstake();}}>
                      Withdraw Gems
                    </a>
                    <a href="#" className="upgrade-gems">
                      upgrade Again
                    </a>
                  </div> */}
                </div>
                {/* <p className="bottom-para">
                  *In order to enter the first round you need to be a premium
                  leader and hold total amount of at least 30k gems{" "}
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        className="stakingmodal premiumstake-modal"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Upgrade to Premium</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="stakingmodal">
            <div className="inner-content">
              <img
                src="\assets\premium\modalbg.png"
                alt="img"
                className="img-fluid modalbg-stake"
              />
              <img
                src="\assets\premium-icon1.svg"
                alt="img"
                className="img-fluid"
              />
              <h5>
                Are you sure you want to <br />
                upgrade to GEMS PREMUM?
              </h5>
              <p>
                You will be paying $400 in $GEMS to enjoy 1 year of Gems
                premium.
              </p>
            </div>
            <div className="d-flex justify-content-center mb-4">
              <p className="text-danger ">{walletError}</p>
            </div>
            <div className="stakingbtns">
              <button
                onClick={() => {
                  handleClose();
                }}
                className="cancelbtn"
              >
                Cancel
              </button>
              <button
                className="confirmbtn"
                onClick={() => {
                  Stake();
                }}
              >
                Yes Upgrade
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="stakingmodal"
        show={show1}
        onHide={handleClose1}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Upgrade to Premium</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="addedmodal">
            <img
              src="\assets\tickimg.svg"
              alt="addedimg"
              className="addedimg"
            />
            <h6 className="successhead">Upgraded to premium</h6>
            <p className="succesaspara">
              Enjoy 100% of your rewards and all <br /> the premium features!
            </p>
            <button
              className="greybtn w-100"
              onClick={() => {
                handleClose1();
              }}
            >
              Okay
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="stakingmodal"
        show={show2}
        onHide={handleClose2}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Upgrade to Premium</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="addedmodal">
            <img
              src="\assets\premium\cross.svg"
              alt="addedimg"
              className="addedimg"
              style={{ width: "100px" }}
            />
            <h6 className="successhead">Insufficient GEMS Balance</h6>
            <p className="succesaspara">
              You do not have enough GEMS in <br /> your wallet!
            </p>
            <button
              className="greybtn w-100"
              onClick={() => {
                handleClose2();
              }}
            >
              Okay
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="stakingmodal"
        show={show3}
        onHide={handleClose3}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="addedmodal">
            <img
              src="\assets\tickimg.svg"
              alt="addedimg"
              className="addedimg"
            />
            <h6 className="successhead">18,000 GEMS Unstaked</h6>
            <p className="succesaspara">Please check your wallet for details</p>
            <button
              className="greybtn w-100"
              onClick={() => {
                handleClose3();
              }}
            >
              Okay
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Staking;
