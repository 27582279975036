import React from "react";
import "./terms.scss";
import Header from "../landing/navbar/Header";
import Footer from "../landing/footer/Footer";

const Terms = () => {
  return (
    <>
      <Header />
      <section className="termsmain">
        <div className="termsinner">
          <div className="innertop">
            <h4 className="tophead">Terms & Condition</h4>
            <p className="toppara">
              To ensure compliance with regulatory requirements, Gems requires
              all leaders to accept these terms and conditions and complete the
              KYC process.
            </p>
          </div>
          <div className="innermid">
            <div className="scrollbox">
              <h6 className="scrollhead">PROMOTION TERMS & CONDITIONS</h6>
              <p className="scrollpara">
                Please carefully read these terms and conditions before engaging
                with the Company and accepting them as they affect your
                obligations and legal rights, including, but not limited to,
                waivers of rights and limitations of liability. If you do not
                agree with these terms and conditions or if you are a prohibited
                person, then you are not allowed to engage with the Company, and
                provide promotion services to us.
              </p>
              <p className="scrollpara">
                Those Promotion Terms and Conditions (the{" "}
                <strong>"Terms"</strong>) is entered into as of your acceptance
                of the Terms (<strong>"Effective Date"</strong>) by and between
                us Gems Lab Limited (the <strong>"Company"</strong>), and you
                (the <strong>"Promoter"</strong>). Each a{" "}
                <strong>"Party"</strong> and together the{" "}
                <strong>"Parties"</strong>.
              </p>
              <p className="scrollpara">
                <strong>WHEREAS</strong>, Company is engaged in the development
                of a blockchain-based crowdfunding Platform (the
                <strong>"Platform"</strong>). On the Platform, newly formed and
                to-be formed companies focused on blockchain technology can
                pitch and post their business cases and perform a crowd
                fundraise for their projects (the <strong>“Projects”</strong>);
                and
              </p>

              <p className="scrollpara">
                <strong>WHEREAS</strong>, Company may offer additional services,
                including but not limited to participation in crowdfunding
                Platform, marketing events, market-making, and software services
                (the <strong>"Services"</strong>); and
              </p>

              <p className="scrollpara">
                <strong>WHEREAS</strong>, Promoter is interested in promoting
                the Projects to its followers and / or client base and / or
                business partners and / or any third party (
                <strong>"End Users"</strong>), while offering them to invest in
                the Projects; and
              </p>

              <p className="scrollpara">
                <strong>WHEREAS</strong>, Promoter wishes to obtain from Company
                a non-exclusive license to promote the Projects to the End Users
                via the Platform, and Company wishes to grant Promoter a license
                to promote the Projects to the End Users via the Platform for
                the Consideration (as defined below), all in accordance with the
                terms and conditions set out herein.
              </p>
              <p className="scrollpara">
                {" "}
                <strong>NOW, THEREFORE,</strong> the Parties agree as follows:
              </p>
              <h6 className="scrollhead">Definitions</h6>
              <p className="scrollpara">
                For purposes of this Terms, the following capitalized terms will
                have the following meaning:
              </p>
              <p className="scrollpara">
                <strong>"Applicable Legislation"</strong> means all applicable
                legislation and regulations, and all directives, requirements
                and guidelines of any Regulators.
              </p>
              <p className="scrollpara">
                <strong>"End Users"</strong> mean the Promoter’s followers and /
                or client base and / or business partners and / or any third
                party who had: 1) invested in the Projects; 2) have completed
                the registration process with the applicable Projects; and 3)
                made a contribution to the Project via the Platform.
              </p>
              <p className="scrollpara">
                <strong>“Business Day"</strong> means a day, which is not a
                Saturday or a Sunday nor a bank or public holiday in British
                Virgin Islands.
              </p>
              <p className="scrollpara">
                <strong>"Company’s System"</strong> means the computer system
                maintained, operated and managed by the Company or its related
                entities (or by other third parties contracted by either of them
                for such purpose), comprising, among other things, all hardware,
                software, databases, and back-office systems including all
                marketing affiliates management, financial reporting, and CRM
                functions.
              </p>
              <p className="scrollpara">
                <strong>"Intellectual Property Rights"</strong> means all
                intangible legal rights, titles and interests evidenced by or
                embodied in or connected or related to the following: (i) all
                inventions (whether patentable or un-patentable and whether or
                not reduced to practice), all improvements thereto, patents and
                patent applications, and any divisional, continuation,
                continuation in part, extension, reissue, renewal or
                re-examination of patent issuing therefrom (including any
                foreign counterparts); (ii) any work of authorship, regardless
                of copyright ability, copyrightable works (including moral
                rights); (iii) mask works and integrated circuit layouts and
                applications and registrations thereof; (iv) computer software,
                including any and all software implementations of algorithms,
                models, methodologies, artwork and designs, whether in source
                code or object code; (v) databases and compilations, including
                any and all data and collections of data, whether machine
                readable or otherwise; (vi) designs and any applications and
                registrations thereof; (vii) all trade secrets, confidential
                information and business information; (viii) trademarks, service
                marks, trade names, certification marks, collective marks,
                logos, brand names, business names, domain names, corporate
                names, trade styles and trade dress, get-up, and other
                designations of source or origin and all and applications and
                registrations thereof; (ix) all documentation, including user
                manuals and training materials relating to any of the foregoing
                and descriptions, flow-charts and other work product used to
                design, plan, organize and develop any of the foregoing; and (x)
                all other proprietary rights, industrial rights and any other
                similar rights.
              </p>
              <p className="scrollpara">
                <strong>"Promotion fees List"</strong> means the pricing for
                each of the promotion of the applicable Project as described in
                the table set out in <strong>Appendix A.</strong>
              </p>
              <p className="scrollpara">
                <strong>"Regulators"</strong> means the regulatory authority (or
                authorities) that may require the Promoter to obtain any
                license, authorization, registration, permit, certification,
                approval or consent, or any other applicable regulatory or
                governmental authority relating (as the context requires) this
                Terms.
              </p>
              <p className="scrollpara">
                <strong>"Restricted Territories"</strong> means the restricted
                countries as stipulated on the Company’s website{" "}
                <a href="https://gems.vip/terms" target="_blank">
                  https://gems.vip/terms
                </a>{" "}
                and any country or territory that has been designated as a
                "non-cooperative country or territory" by the Financial Action
                Task Force, or any jurisdiction which is subject to any
                country-wide or territory-wide restrictive measures or sanctions
                or embargoes administered or enforced by any applicable country,
                organization, union or government, including any updates to the
                list of countries, territories, or Applicable Legislation as may
                be amended from time to time.
              </p>
              <p className="scrollpara">
                <strong>"Term"</strong> shall have the meaning set out in
                <strong> Appendix A</strong>.
              </p>
              <p className="scrollpara">
                <strong>"Platform"</strong> has the meaning set out in the
                preamble, including any updates and upgrades made generally
                available.
              </p>
              <h6 className="scrollhead">License to Use the Platform</h6>

              <p className="scrollpara">
                <strong>License.</strong> Upon the Effective Date and for the
                duration of the Term, Company will grant to the Promoter a
                revocable, non-exclusive, non-transferable (save for
                sub-licensing in accordance with the provisions below), license
                to use the Platform for the sole purpose of promoting the
                Projects via the Platform to the End Users.
              </p>
              <p className="scrollpara">
                <strong>Restrictions.</strong> Apart from the rights explicitly
                granted herein, the Promoter shall have no other rights, express
                or implied, in or to the Platform nor in or to the Company’s
                System. Without derogating from the generality of the foregoing,
                Promoter may not, nor permit any third party to, without
                Company’s prior written consent: (i) modify, copy, translate,
                create derivative works from or distribute the Company’s System
                and/or the Platform or any part thereof, nor create any copy,
                adaptation, transcription, or merge any portion of the Platform
                and/or the Company’s System; (ii) reverse engineer, disassemble,
                decompile or otherwise attempt to derive the source code of the
                Company’s System ; (iii) remove any copyright, proprietary or
                similar notices from the Company’s System; (iv) offer the use of
                the Platform to any Broker who does not hold a valid license in
                the territory to which any Applicable Regulations apply or who
                is otherwise not permitted to access the Company’s System under
                any Applicable Regulations; (v) violate any applicable anti-SPAM
                rules, regulation, legislation and standard practice; or (vi)
                permit any officer, director, employee, consultant or agent of
                Promoter or its Related Entities to register as a Broker. In the
                event of a breach of the above restrictions, the Promoter’s
                license granted herein shall be deemed to be immediately and
                automatically revoked by Company and waived by the Promoter.
              </p>
              <p className="scrollpara">
                <strong>Reserved Rights.</strong> All rights with respect to the
                Platform not specifically granted to Promoter hereunder are
                reserved by Company. All Intellectual Property Rights evidenced
                by or embodied in or related to the Services, including any
                customizations made to the Services, shall be owned solely by
                Company. Promoter acknowledges that Company does not convey any
                Intellectual Property Rights to Promoter under this Terms, and
                that Promoter has not, does not, and shall not acquire any
                rights with respect to the Platform or any derivative works
                thereof.
              </p>
              <p className="scrollpara">
                <strong>Availability.</strong> Company may restrict the
                availability of the Platform (or any part thereof) to potential
                and existing End Users in any territory if Company believes that
                any legal or regulatory environment (or any actual or proposed
                changes thereto) in the relevant territory will expose Company
                or any of its Related Entities to the risk of legal, regulatory
                or economic sanctions.
              </p>
              <h6 className="scrollhead">
                Company’s Warranties and Disclaimer
              </h6>
              <p className="scrollpara">
                <strong>Warranties.</strong> Company shall use reasonable
                efforts to provide uninterrupted availability and operation of
                the Platform, and in any event excluding reasonable periods for
                downtime on account of planned Maintenance, installation of
                periodic upgrades and updates and related support.
              </p>
              <p className="scrollpara">
                <strong>Disclaimer.</strong> Promoter acknowledges that Company
                is solely a technology provider under this Terms and is not
                providing any investment related services, including, without
                limitation: consulting, investment advice, or any investment
                activities, or portfolio management, or trading platforms
                management, or ancillary service, or any financial advice, or
                financial service, or any other service which requires a
                license, authorization, certification, approval, permit or
                consent from or registration with any Regulator, or which
                requires an appointment as an agent, or as an authorized
                representative, or requires any other appointment or
                registration, according to Applicable Legislation and as defined
                therein, and Company expressly disclaims and Promoter expressly
                acknowledges that Company does not provide any legal, financial
                or otherwise regulated advice or services.
              </p>
              <p className="scrollpara">
                Promoter acknowledges that Company’s provision of the Platform
                is dependent on third party factors
                <strong> ("Third Party Factors") </strong> such as facilities,
                networks, connectivity, and acts or omissions controlled by
                third party providers such as internet providers, Regulators and
                other third parties. Performance of the Platform may be affected
                significantly by such Third Party Factors. Third Party Factors
                are deemed to be outside Company’s span of control. Company will
                have no liability for any reduction, interruption, termination
                or suspension of the access to the Platform or the Platform’s
                performance related to any Third Party Factors that Company
                reasonably believes to be outside of its control, including
                without limitation data center outages and failures of third
                party software.
              </p>
              <p className="scrollpara">
                OTHER THAN THE WARRANTIES SET OUT ABOVE, COMPANY DOES NOT
                WARRANT THAT THE PLATFORM, OR ANY PART THEREOF, WILL MEET
                PROMOTER'S REQUIREMENTS, THAT ITS OPERATION WILL BE
                UNINTERRUPTED, OR ERROR, OR BUG FREE. COMPANY HEREBY DISCLAIMS
                ANY OTHER WARRANTY , EXPRESS OR IMPLIED, INCLUDING, WITHOUT
                LIMITATION, ANY WARRANTY OF NON-INFRINGEMENT, MERCHANTABILITY OR
                FITNESS FOR A PARTICULAR PURPOSE.
              </p>
              <h6 className="scrollhead">
                Promoter’s Obligations and Warranties
              </h6>
              <p className="scrollpara">
                <strong>Obligations.</strong> The Promoter will cooperate with
                the Company in order to promote the Projects via the Platform to
                the End Users.
              </p>
              <p className="scrollpara">
                <strong>Marketing and Promotion:</strong> Using its best efforts
                to actively and effectively advertise, market and promote the
                Projects to End Users via the Platform throughout the Term, and
                cooperating with Company regarding any copyright, trademark or
                branding. All costs relating to any promotional and marketing
                activities of the Platform and / the Projects shall be borne
                exclusively by the Promoter. Any promotional and marketing
                activities in the Restricted Territories, or other restricted
                territories provided by the Company, at its sole discretion to
                the Promoter, from time to time, are expressly prohibited.
                Should the Promoter be approached by any third party from the
                Restricted Territories for the sublicensing of the Services,
                Promoter shall promptly notify the Company and either refer such
                third party to the Company or take an order from such party, all
                as shall be directed by the Company in writing at its sole
                discretion.
              </p>
              <p className="scrollpara">
                <strong>Promoter Warranties:</strong> the Promoter hereby
                represents, warrants and undertakes to the Company as follows:
              </p>
              <ul>
                <li>
                  Promoter is legally competent and has full authority to enter
                  into these Terms.
                </li>
                <li>
                  By entering into these Terms, Promoter is not violating the
                  terms of any other agreements with third parties or applicable
                  Laws.
                </li>
                <li>
                  To not, and to not allow, authorize or encourage any third
                  party to directly or indirectly engage in any Prohibited
                  Activities, and will use commercially reasonable efforts to
                  prevent Prohibited Activities.
                </li>
                <li>
                  To comply with all applicable Laws in the performance of its
                  obligations or any of its activities under these Terms,
                  including but not limited to all Laws and regulations
                  regarding data protection, privacy, marketing and spam Laws,
                  payments, financial services, securities, anti-money
                  laundering, sanctions, cryptocurrency and any digital assets.
                </li>
                <li>
                  Promoter has, and shall obtain and maintain all regulatory and
                  other approvals, permits, authorizations, consents,
                  registrations and licenses that are or may be required under
                  any Law for operating as a Promoter and conducting any
                  associated activity under these Terms, to the extent that such
                  are required.
                </li>
                <li>
                  As part of its activity under the Terms, the Promoter shall
                  place appropriate warnings where required to do so under any
                  applicable Laws.
                </li>
              </ul>
              <h6 className="scrollhead">Reporting and Payment</h6>
              <p className="scrollpara">
                <strong>Reports.</strong> The Company will provide the Promoter
                with 24/7 access to its back office where it will be able to
                review a report detailing the amount of the End Users that
                invested in the Projects (the
                <strong>"Report"</strong>). Based on the Report, and the actual
                investments of the End Users in the Project, the Company will
                calculate all fees owed to the Promoter, the exact commission
                (in percentages) due to the Promoter, will be agreed by the
                Parties before the acceptance of those Terms.
              </p>
              <p className="scrollpara">
                <strong>Taxes.</strong> All payments due to the Promoter under
                the Terms will be exclusive of taxes, duties, levies, tariffs,
                and other governmental charges (including, without limitation, V
                AT, if applicable) (collectively,
                <strong>"Taxes"</strong>). The Promoter will be responsible for
                payment of all Taxes and any related interest and penalties
                resulting from any payments made hereunder.
              </p>
              <h6 className="scrollhead">Mutual Warranties.</h6>
              <p className="scrollpara">
              Each Party hereby represents, warrants and covenants to the other Party as follows:
              </p>
              <p className="scrollpara">
              It has the full right, power, legal capacity and authority to perform its obligations under these Terms;
              </p>
              <p className="scrollpara">
              Neither its entrance into these Terms nor the performance of its obligations hereunder will conflict with, or breach the terms, conditions or provisions of, or default under any other Terms to which it is a party;
              </p>
              <p className="scrollpara">
              There is no action, suit or proceeding at law or in equity now pending or, to its knowledge, threatened by or against or affecting it, which would substantially impair its right to carry on its business as contemplated herein or to enter into or perform its obligations under these Terms, or which adversely affects its financial condition or operations;
              </p>
              <p className="scrollpara">
              It shall comply with relevant data protection legislation in relation to all User information which is personal data (including any sensitive personal data); and
              </p>
              <p className="scrollpara">
              It shall obtain and maintain any, and all, regulatory approvals, licenses and consents required for the performance of its obligations under these Terms if applicable, and any additional regulatory approvals, licenses and consents which may become required for such purpose in the future.
              </p>
              <p className="scrollpara">
                <strong>Confidentiality.</strong> Each Party shall keep confidential and shall not disclose to any third party any and all information disclosed by one Party to the other Party, prior to or during the Term of these Terms, that can reasonably be understood as confidential, including the contents of these Terms ("Confidential Information"), and shall use such Confidential Information solely for the performance of its obligations under these Terms. The obligations in this Section ‎7 shall not apply to Confidential Information which the receiving party is able to show by written evidence: (i) was already known to the receiving party prior to disclosure of Confidential Information; (ii) was in the public domain or which becomes part of the public domain through no fault of the receiving party; or (iii) is required to be disclosed by Applicable Legislation so long as the receiving party provides with prompt written notice of such disclosure. Neither Party may issue publicity or general marketing communications concerning its involvement with the other Party without the other Party’s prior written approval, unless such public communication is required by Applicable Legislation or the rules of any listing authority or stock exchange or any other regulatory body.
              </p>
              <h6 className="scrollhead">Term and Termination</h6>
              <p className="scrollpara">
                <stong>Term.</stong> These Terms shall come into effect on the Effective Date and shall continue for undefined term, unless mutually agreed otherwise, notwithstanding the above either Party may terminate these Terms, at any time, for any reason or no reason, by providing the other party with at least fourteen (14) days of advance, written notice of termination.
                
              </p>
              <p className="scrollpara">
                <stong>Termination for Cause by Either Party.</stong> These Terms may be terminated by either Party for cause by written notice to the other, with immediate effect, upon the occurrence of any of the following events: (a) any material default or breach by the other Party of its obligations hereunder, which either is incurable by its nature or has not been cured within seven (7) days after the breaching Party has received written notice specifying such breach or default; (b) the other Party ceases or threatens to cease doing business in the ordinary course; or (c) in the event that by reason of any order or judgment of government, court or other authority, the continued operation of these Terms in all its provisions is prevented or delayed for an unspecified period or made impossible or impractical.
              </p>
              <p className="scrollpara">
                <strong>Termination for Cause by Company. </strong>{" "}
                Notwithstanding Section 8.2, these Terms may be terminated immediately by Company if the Promoter, or attempts to promote the Platform in any manner which is illegal, unlawful, misleading or that is un reasonable, in the sole and absolute opinion of Company, and which may bring the Company into disrepute or adversely affects the goodwill and good name of the Company.
              </p>
              <p className="scrollpara">
                <strong>Consequences of Termination.</strong>  Upon termination of these Terms for any reason: (i) Promoter shall not be entitled to promote the Platform and/or the Projects and immediately cease any and all activates relating or connected to the Platform and/or the Projects, including but not limited to promotion and marking of the Platform and/or the Projects; (ii) each Party shall immediately cease using any Confidential Information of the other and shall return or destroy all Confidential Information and all of such other Party’s materials and all copies thereof, other than backup copies, and promptly provide the other Party with a written confirmation of such destruction, unless the destruction of the Confidential information is not permitted under the applicable laws and regulations; (iii) each Party shall immediately cease using the trademarks and other materials owned by the other Party; (iv) the Promoter shall not be entitled to any commission and or fees to be paid to it as of termination day. 
              </p>
              <p className="scrollpara">
                <strong>Force Majeure.</strong> Except for obligations to pay amounts due under these Terms, neither Party hereto shall be liable for any loss, damage, or penalty resulting from such Party's failure to perform its obligations hereunder when such failure is due to events beyond its control, including, without limitation, flood, earthquake, fire, acts of God, military insurrection, civil riot, or labor strikes.
              </p>
              <h6 className="scrollhead"> Liability; Indemnification.</h6>
              <p className="scrollpara">
                <strong>Limitation of Liability.</strong> EXCEPT WITH RESPECT TO THE INDEMNIFICATION OBLIGATIONS UNDER SECTION ‎11.2, IN NO EVENT SHALL EITHER PARTY’S LIABILITY UNDER, ARISING OUT OF, OR RELATING TO THESE TERMS, INCLUDING ANY LIABILITY ARISING OUT OF PROMOTER’S TermsS WITH ITS END USERS AND/OR ITS END USERS’ TermsS WITH ANY THIRD PARTIES, EXCEED THE TOTAL AMOUNT OF FEES PAID TO COMPANY DURING THE TWELVE (12) MONTHS PRECEDING ANY CLAIM. IN NO EVENT WILL EITHER PARTY BE LIABLE FOR LOST PROFITS, LOSS OF USE, LOSS OF DATA, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR ANY OTHER SPECIAL, INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES, HOWSOEVER CAUSED, AND ON ANY THEORY OF LIABILITY, WHETHER FOR BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE AND STRICT LIABILITY), OR OTHERWISE, WHETHER OR NOT THE PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
              </p>
              <p className="scrollpara">
                <strong>Indemnification.</strong> Promoter undertakes to defend, indemnify and hold Company (and its related entities, directors, officers and employees) harmless from and against any and all claims asserted against Company in connection with: (i) Promoter’s advertising, marketing and promotional efforts; (ii) any breaches of its obligations, warranties or representations. 
              </p>
              <p className="scrollpara">
                <strong>Indemnification Procedure. </strong> Subject to Sections 10.1 and 10.2 above, each Party will indemnify and hold the other Party and its officers, employees, directors, agents, successors and assigns (together, the "Indemnified Party") harmless from and against any and all damages, losses, costs and expenses, including without limitation reasonable attorneys’ fees, related to claims of third parties arising out of or based upon the other Party’s (the "Indemnifying Party") material breach of these Terms, fraud, gross negligence or willful misconduct. As a condition to the Indemnified Party’s rights under this Section 10.3, it shall be required to: (a) give the Indemnifying Party notice of the relevant claim as soon as practicable, but in all events, within a period of time that will not prejudice the rights of the Indemnifying Party; (b) reasonably cooperate with the Indemnifying Party, at the Indemnifying Party’s expense, in the defense of that claim; and (c) assign to the Indemnifying Party the right to control the defense and settlement of any claim, except that the Indemnifying Party will not enter into any settlement that involves a remedy other than the payment of money by the Indemnifying Party without the Indemnified Party’s prior written approval, which shall not be unreasonably withheld or delayed. The Indemnified Party will have the right to participate in the defense at its own expense.
              </p>
              <h6 className="scrollhead">Miscellaneous</h6>
              <p className="scrollpara">
                <strong>Headings.</strong> The headings of the Sections in these Terms are for the purpose of convenience and reference only.
              </p>
              <p className="scrollpara">
                <strong>Relationship between the Parties.</strong> Company is an independent contractor and nothing contained in these Terms shall be deemed to create a joint venture, Promotership, employment, agency or similar arrangement between the Parties.
              </p>
              <p className="scrollpara">
                <strong>No Obligation.</strong> Under no circumstances shall either Party have the authority to bind, obligate or commit the other Party in any way whatsoever or to assume debts or obligations on its behalf, nor shall either Party represent itself as having such authorities at any time.
              </p>
              <p className="scrollpara">
                <strong>
                Dispute Resolution; Governing Law; Jurisdiction.
                </strong>{" "}
                The Parties will attempt to settle any claim or controversy arising out of these Terms through good faith negotiation. If a dispute occurs, the Parties will first submit the dispute in writing to a senior executive from each Party to be resolved between them within thirty (30) days. These Terms and any matters relating hereto shall be governed by, and construed in accordance with the laws of British Virgin Islands. The Parties irrevocably agree that courts of Road Town in British Virgin Islands shall have exclusive jurisdiction to hear any claim, disputes, controversies or matter arising out of or in connection with or in relation to there Terms.
              </p>
              <p className="scrollpara">
                <strong>Entire Terms.</strong> These Terms constitutes the complete understanding and Terms of the Parties and supersedes all prior negotiations, understandings and Terms with respect to the subject matter of these Terms. These Terms may not be altered, amended, modified or supplemented in any respect except by writing signed by an authorized representative of each Party. If any provision of these Terms is held to be invalid, unenforceable or illegal for any reason, the validity or enforceability of any or all of the remaining portions shall not be affected.
              </p>
              <p className="scrollpara">
                <strong>Assignment.</strong> Neither Party shall assign any of its rights under these Terms, except that Company may assign or transfer these Terms, in whole or in part, to any of its Related Entities or to any successors in interest of substantially all of the assets of that part of Company’s business to which there Terms relates. Any attempted assignment contrary to this section shall be null and void.
              </p>
              <p className="scrollpara">
                <strong>Notice.</strong> All notices in connection with these Terms or the transactions contemplated hereunder shall be in writing, and shall be delivered either by e-mail, in person, or by internationally recognized courier. Any notice sent by one party to the other shall be deemed received seventy-two (72) hours after being posted by registered mail or shall be deemed received on the day of delivery if delivered in person or sent by email with a delivery receipt confirmation.
              </p>
              <p className="scrollpara">
                <strong>Waiver.</strong> The failure of a Party to require performance of any provision of these Terms shall not be construed as a waiver of that Party’s rights to insist on performance of that same provision, or any other provision, at some other time. No right may be waived except in writing, signed by the Party entitled to assert the right. The waiver by a Party of any right created by these Terms in one or more instances shall not be construed as a further continuing waiver of such right or any other right created by these Terms.
              </p>
              <p className="scrollpara">
                <strong>Effect of Provisions.</strong> If any provision of these Terms shall be declared by any court of competent jurisdiction to be illegal, void or unenforceable, all other provisions of these Terms shall not be affected and shall remain in full force and effect.
              </p>
            </div>
            <div className="checkboxmainnnnnn">
              <label class="checkbox m-0" for="ch2">
                <input type="checkbox" id="ch2" />
                <span class="checkmark"></span>
              </label>
              <p className="checkboxpara">
                I have read and understood the disclaimer and hereby agree to
                its terms and conditions.
              </p>
            </div>
            <button className="btndull">Continue</button>
            {/* <button className="btngreen">
            Continue
            </button> */}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Terms;
