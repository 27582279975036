import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import { getMinersClaimsOldContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";

const CurrentRoundNodeMinerOld = () => {
  const [balance, setBalance] = useState(null);
  // const { account } = useWeb3React();
  const web3 = useWeb3();

  const MinersAddressOld = Environment.MinersClaimOld;

  const MinersContractClaimsOld = getMinersClaimsOldContract(
    MinersAddressOld,
    web3
  );

  const project = localStorage.getItem("project");
  const currentRoundNodeMinerOld = useCallback(async () => {
    if (project == "NODEMINER") {
      try {
        const buy = await MinersContractClaimsOld.methods.currentWeek().call();
        console.log("current week: ", buy);
        return buy;
      } catch (error) {
        console.log("dlsflasdfjlasdf", error);
        throw error;
      }
    }
  }, [MinersContractClaimsOld, web3]);
  return { currentRoundNodeMinerOld: currentRoundNodeMinerOld };
};
export default CurrentRoundNodeMinerOld;
