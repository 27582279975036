import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getMinersClaimsContract } from "../../utils/contractHelpers";
export const AllClaimableNodeMiner = () => {
  const web3 = useWeb3();

  const MinersAddress = environment.MinersClaim;

  const MinersClaimsContract = getMinersClaimsContract(MinersAddress, web3);

  const project = localStorage.getItem("project");
  const CheckAllClaimableNodeMiner = useCallback(
    async (walletAddress, round, token) => {
      if (project == "NODEMINER") {
        try {
          console.log(
            "checking pending claims: ",
            project,
            walletAddress,
            round,
            token
          );

          const claimAmount = await MinersClaimsContract.methods
            .pendingClaims(walletAddress, round, token)
            .call();
          console.log("checked pending claims for token: ", claimAmount);

          return claimAmount;
        } catch (error) {
          throw error;
        }
      }
    },
    [MinersClaimsContract]
  );

  return { CheckAllClaimableNodeMiner: CheckAllClaimableNodeMiner };
};

export default AllClaimableNodeMiner;
