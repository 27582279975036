import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import { getMinersClaimsV2Contract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";

const CurrentRoundNodeMinerV2 = () => {
  const [balance, setBalance] = useState(null);
  // const { account } = useWeb3React();
  const web3 = useWeb3();

  const MinersAddressV2 = Environment.MinersClaimV2;

  const MinersContractClaimsV2 = getMinersClaimsV2Contract(
    MinersAddressV2,
    web3
  );

  const project = localStorage.getItem("project");
  const currentRoundNodeMinerV2 = useCallback(async () => {
    if (project == "NODEMINER") {
      try {
        const buy = await MinersContractClaimsV2.methods.currentWeek().call();
        console.log("current week: ", buy);
        return buy;
      } catch (error) {
        console.log("dlsflasdfjlasdf", error);
        throw error;
      }
    }
  }, [MinersContractClaimsV2, web3]);
  return { currentRoundNodeMinerV2: currentRoundNodeMinerV2 };
};
export default CurrentRoundNodeMinerV2;
